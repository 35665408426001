import {ResultsGridUnitTypesV2} from "./resultGridUnitTypes";
import {ResultSummaryTypes} from "./resultSummaryType";

const inseminatedGiltsAmount =
    "SELECT week, month, year, day, group_nr, COUNT(DISTINCT AnmID) as inseminated FROM ParamsTable WHERE key='boar' AND cycle = CONST('FIRST_CYCLE_INDEX')";
const inseminatedSowsAmount =
    "SELECT week, month, year, day, group_nr, COUNT(DISTINCT AnmID) as inseminated FROM ParamsTable WHERE key='boar' AND cycle > CONST('FIRST_CYCLE_INDEX')";
const inseminatedAmount =
    "SELECT week, month, year, day, group_nr, COUNT(DISTINCT AnmID) as inseminated FROM ParamsTable WHERE key='boar'";
const usgAmount =
    "SELECT week, month, year, day, group_nr, COUNT(DISTINCT CASE WHEN key = 'usgResult' THEN AnmID ELSE NULL END) as amount, COUNT(DISTINCT AnmID) as inseminated FROM ParamsTable";
const giltsInseminationEffectiveness =
    "SELECT week, month, year, day, group_nr, (COUNT(DISTINCT CASE WHEN (key = 'usgResult' AND [value] = 1) OR (key = 'bornPiglet') THEN AnmID END) / COUNT(DISTINCT AnmID) * 100) as effectiveness FROM ParamsTable WHERE cycle = CONST('FIRST_CYCLE_INDEX')";
const sowsInseminationEffectiveness =
    "SELECT week, month, year, day, group_nr, (COUNT(DISTINCT CASE WHEN (key = 'usgResult' AND [value] = 1) OR (key = 'bornPiglet') THEN AnmID END) / COUNT(DISTINCT AnmID) * 100) as effectiveness FROM ParamsTable WHERE cycle > CONST('FIRST_CYCLE_INDEX')";
const inseminationEffectiveness =
    "SELECT week, month, year, day, group_nr, (COUNT(DISTINCT CASE WHEN (key = 'usgResult' AND [value] = 1) OR (key = 'bornPiglet') THEN AnmID END) / COUNT(DISTINCT AnmID) * 100) as effectiveness FROM ParamsTable";
const farrowingSows =
    "SELECT week, month, year, day, group_nr, COUNT(DISTINCT AnmID) - COUNT(DISTINCT CASE WHEN key = 'bornPiglet' OR key = 'technologyGroupRemoval' THEN AnmID END) as amount, MIN(DISTINCT CASE WHEN key = 'expectedFarrowing' THEN [value] END) as expectedFarrowing FROM ParamsTable";
const repetitionGiltsAmount =
    "SELECT week, month, year, day, group_nr, COUNT(DISTINCT AnmID) as repetitions FROM ParamsTable WHERE key='repetitionDays' AND cycle = CONST('FIRST_CYCLE_INDEX')";
const repetitionSowsAmount =
    "SELECT week, month, year, day, group_nr, COUNT(DISTINCT AnmID) as repetitions FROM ParamsTable WHERE key='repetitionDays' AND cycle > CONST('FIRST_CYCLE_INDEX')";
const repetitionAmount =
    "SELECT week, month, year, day, group_nr, COUNT(DISTINCT AnmID) as repetitions FROM ParamsTable WHERE key='repetitionDays'";
const averageRepetitionTime =
    "SELECT week, month, year, day, group_nr, AVG([value]) as repetitionTime FROM ParamsTable WHERE key='repetitionDays'";
const averageSeparationToInseminationDays =
    "SELECT week, month, year, day, group_nr, AVG([value]) as days FROM ParamsTable WHERE key = 'separationInseminationDays'";
const retention =
    "SELECT week, month, year, day, COUNT(DISTINCT CASE WHEN s2.maxCycle > CONST('FIRST_CYCLE_INDEX') THEN AnmID END) / COUNT(DISTINCT AnmID) * 100 as retention FROM (SELECT AnmID, week, month, year, day FROM ParamsTable WHERE key = 'boar' AND cycle = CONST('FIRST_CYCLE_INDEX')) as s1 INNER JOIN (SELECT AnmID, MAX(cycle) as maxCycle FROM ParamsTable GROUP BY AnmID) as s2 ON s1.AnmID = s2.AnmID {{END_QUERY}}";

const expectedFarrowing = `SELECT week, month, year, day, group_nr, COUNT(DISTINCT AnmID) as amount, MIN([value]) as expectedFarrowing FROM (SELECT pt.*, IIF(at.DtaDthTime OR at.DtaDelTime, true, false) as IsDead FROM (SELECT * FROM ParamsTable WHERE key = 'expectedFarrowing') pt JOIN AnimalsTable at ON pt.AnmID = at.AnmID) WHERE key = 'expectedFarrowing' AND IsDead <> true`;
const farrowedAmount =
    "SELECT week, month, year, day, group_nr, COUNT(DISTINCT AnmID) as farrowedAmount FROM ParamsTable WHERE key = 'shiftFarrowDay'";
const shiftFarrowDay =
    "SELECT week, month, year, day, group_nr, AVG([value]) as shiftFarrowDay FROM ParamsTable WHERE key = 'shiftFarrowDay'";
const giltsFarrowingEffectiveness =
    "SELECT week, month, year, day, group_nr, (COUNT(DISTINCT CASE WHEN key = 'bornPiglet' THEN AnmID END) / COUNT(DISTINCT AnmID) * 100) as effectiveness FROM ParamsTable WHERE cycle = CONST('FIRST_CYCLE_INDEX')";
const sowsFarrowingEffectiveness =
    "SELECT week, month, year, day, group_nr, (COUNT(DISTINCT CASE WHEN key = 'bornPiglet' THEN AnmID END) / COUNT(DISTINCT AnmID) * 100) as effectiveness FROM ParamsTable WHERE cycle > CONST('FIRST_CYCLE_INDEX')";
const farrowingEffectiveness =
    "SELECT week, month, year, day, group_nr, (COUNT(DISTINCT CASE WHEN key = 'bornPiglet' THEN AnmID END) / COUNT(DISTINCT AnmID) * 100) as effectiveness FROM ParamsTable";
const bornPiglets =
    "SELECT week, month, year, day, group_nr, SUM([value]) as piglets FROM ParamsTable WHERE key = 'bornPiglet'";
const averageBornPigletsForSow =
    "SELECT week, month, year, day, group_nr, SUM([value]) / COUNT(DISTINCT AnmID) as average FROM ParamsTable WHERE key = 'bornPiglet'";
const aliveBornPiglets =
    "SELECT week, month, year, day, group_nr, SUM([value]) as piglets FROM ParamsTable WHERE key = 'liveBornPiglet'";
const aliveBornPigletsForSow =
    "SELECT week, month, year, day, group_nr, SUM([value]) / COUNT(DISTINCT AnmID) as average FROM ParamsTable WHERE key = 'liveBornPiglet'";
const weakBornPiglets =
    "SELECT week, month, year, day, group_nr, SUM([value]) as piglets FROM ParamsTable WHERE key = 'weakBornPiglet'";
const weakBornPigletsForSow =
    "SELECT week, month, year, day, group_nr, SUM([value]) / COUNT(DISTINCT AnmID) as average FROM ParamsTable WHERE key = 'weakBornPiglet'";
const weakToAlivePigletsRatio =
    "SELECT week, month, year, day, group_nr, SUM(CASE WHEN key = 'weakBornPiglet' THEN [value] ELSE 0 END) / SUM(CASE WHEN key = 'liveBornPiglet' THEN [value] ELSE 0 END) * 100 as ratio FROM ParamsTable";
const mummyBornPiglets =
    "SELECT week, month, year, day, group_nr, SUM([value]) as piglets FROM ParamsTable WHERE key = 'mummyBornPiglet'";
const mummyBornPigletsForSow =
    "SELECT week, month, year, day, group_nr, SUM([value]) / COUNT(DISTINCT AnmID) as average FROM ParamsTable WHERE key = 'mummyBornPiglet'";
const deadBornPiglets =
    "SELECT week, month, year, day, group_nr, SUM([value]) as piglets FROM ParamsTable WHERE key = 'deadBornPiglet'";
const deadBornPigletsForSow =
    "SELECT week, month, year, day, group_nr, SUM([value]) / COUNT(DISTINCT AnmID) as average FROM ParamsTable WHERE key = 'deadBornPiglet'";
const averagePigletWeight =
    "SELECT week, month, year, day, group_nr, SUM(bornPiglet * bornPigletWeight) / SUM(bornPiglet) as average FROM (SELECT FIRST(week) as week, FIRST(month) as month, FIRST(year) as year, group_nr, AnmID, MAX(CASE WHEN key = 'bornPigletWeight' THEN [value] ELSE NULL END) as bornPigletWeight, SUM(CASE WHEN key = 'bornPiglet' THEN [value] ELSE NULL END) as bornPiglet FROM ParamsTable GROUP BY group_nr, AnmID) WHERE bornPiglet IS NOT NULL AND bornPigletWeight IS NOT NULL";
const numberOfPigletBySow =
    "SELECT week, month, year, day, group_nr, SUM(CASE WHEN key = 'liveBornPiglet' OR key = 'fostering' THEN [value] ELSE -[value] END) as amount FROM ParamsTable WHERE (key = 'liveBornPiglet' OR key = 'fostering' OR key = 'deadPiglet' OR key = 'weanedPiglet')";
const fostering =
    "SELECT week, month, year, day, group_nr, SUM([value]) as fostering FROM ParamsTable WHERE key = 'fostering'";

const separatedLitters =
    "SELECT week, month, year, day, group_nr, COUNT(DISTINCT AnmID) as amount FROM ParamsTable WHERE key = 'weanedPiglet'";
const separationsToPigletHouse =
    "SELECT week, month, year, day, group_nr, SUM([value]) as weaned FROM ParamsTable WHERE key = 'weanedToPigletHouse'";
const separatedPiglets =
    "SELECT week, month, year, day, group_nr, SUM([value]) as amount FROM ParamsTable WHERE key = 'weanedPiglet'";
const separationsToBabyRoom =
    "SELECT week, month, year, day, group_nr, SUM(CASE WHEN key = 'weanedPiglet' THEN [value] ELSE -[value] END) as weaned FROM ParamsTable WHERE (key = 'weanedPiglet' OR key = 'weanedToPigletHouse')";
const separatedPigletForSow =
    "SELECT week, month, year, day, group_nr, SUM([value]) / COUNT(DISTINCT AnmID) as average FROM ParamsTable WHERE key = 'weanedPiglet'";
const weanedPerFarrowing =
    "SELECT week, month, year, day, group_nr, SUM(CASE WHEN key = 'weanedPiglet' THEN [value] END) / COUNT(DISTINCT CASE WHEN key = 'bornPiglet' THEN AnmID END) as weaned FROM ParamsTable"; // to samo
// const separatedLitterWeight = "SELECT week, month, year, day, group_nr, AVG(separationPigletWeight) * (SUM(separatedPiglets) / COUNT(AnmID)) as weight FROM (SELECT AnmID, group_nr, MAX(CASE WHEN key = 'weanedPigletWeight' THEN [value] ELSE NULL END) as separationPigletWeight, MAX(CASE WHEN key = 'weanedToPigletHouse' THEN [value] ELSE NULL END) as separatedPiglets FROM ParamsTable GROUP BY group_nr, AnmID ORDER BY dataEv) WHERE separationPigletWeight IS NOT NULL AND separatedPiglets IS NOT NULL";
const separatedWeight =
    "SELECT week, month, year, day, group_nr, SUM(weight * piglets) as weight FROM (SELECT * FROM (SELECT AnmID, [value] as weight, EvID, group_nr, week, month, year FROM ParamsTable WHERE key = 'weanedPigletWeight') as s1 INNER JOIN (SELECT AnmID, [value] as piglets, EvID FROM ParamsTable WHERE key = 'weanedPiglet') as s2 ON s1.EvID = s2.EvID) {{END_QUERY}}";
const averageSeparatedPigletWeight =
    "SELECT week, month, year, day, group_nr, SUM(weanedPigletWeight * weanedPiglet) / SUM(weanedPiglet) as weight FROM (SELECT * FROM (SELECT AnmID, [value] as weanedPigletWeight, EvID, group_nr, week, month, year FROM ParamsTable WHERE key = 'weanedPigletWeight') as s1 INNER JOIN (SELECT AnmID, [value] as weanedPiglet, EvID FROM ParamsTable WHERE key = 'weanedPiglet') as s2 ON s1.EvID = s2.EvID) {{END_QUERY}}";
const pigletsMortality =
    "SELECT week, month, year, day, group_nr, (SUM(CASE WHEN key = 'deadPiglet' THEN [value] ELSE 0 END) / SUM(CASE WHEN key = 'liveBornPiglet' THEN [value] ELSE 0 END)) * 100 as mortality FROM ParamsTable";
const deadPiglets =
    "SELECT week, month, year, day, group_nr, SUM([value]) as dead FROM ParamsTable WHERE key = 'deadPiglet'";
const deadPigletsForSow =
    "SELECT week, month, year, day, group_nr, SUM(CASE WHEN key = 'deadPiglet' THEN [value] ELSE 0 END) / COUNT(DISTINCT CASE WHEN key = 'shiftFarrowDay' THEN AnmID ELSE NULL END) as dead FROM ParamsTable";
const lactationDays =
    "SELECT week, month, year, day, group_nr, AVG(CASE WHEN key = 'lactationDays' OR key = 'lactationMommyDays' THEN [value] END) as lactationDays FROM ParamsTable";
const culledSows =
    "SELECT week, month, year, day, group_nr, COUNT(DISTINCT AnmID) as animals FROM ParamsTable WHERE (key = 'fallReason' OR key = 'sellAge')";

const separationsFromBabyRoom =
    "SELECT week, month, year, day, group_nr, SUM([value]) as amount FROM ParamsTable WHERE key = 'pigletRoomSeparation'";
const babyRoomFall =
    "SELECT week, month, year, day, group_nr, SUM([value]) as amount FROM ParamsTable WHERE key = 'pigletRoomFall'";

function createKeys(keys) {
    return keys.map((item) => `'${item}'`).join(", ");
}

function createWeightQuery(weightKeys, amountKeys) {
    return `SELECT week, month, year, day, group_nr, SUM(amount * weight) / SUM(amount) as weight FROM (SELECT * FROM (SELECT week, month, year, day, group_nr, [value] as weight, EvID FROM ParamsTable WHERE key in (${createKeys(
        weightKeys
    )})) as s1 INNER JOIN (SELECT [value] as amount, EvID FROM ParamsTable WHERE key IN (${createKeys(
        amountKeys
    )})) as s2 ON s1.EvID = s2.EvID) {{END_QUERY}}`;
}

function createMortalityQuery(deadKeys, addAmountKeys, subtractAmountKeys) {
    return `SELECT week, month, year, day, group_nr, SUM(CASE WHEN key IN (${createKeys(
        deadKeys
    )}) THEN [value] ELSE 0 END) / SUM(CASE WHEN key IN (${createKeys(
        addAmountKeys
    )}) THEN [value] ELSE CASE WHEN key IN (${createKeys(
        subtractAmountKeys
    )}) THEN -[value] ELSE 0 END END) * 100 as mortality FROM ParamsTable WHERE key IN (${createKeys(
        [...deadKeys, ...addAmountKeys, ...subtractAmountKeys]
    )})`;
}

const sowInsertionAmount =
    "SELECT week, month, year, day, COUNT(DISTINCT AnmID) as amount FROM ParamsTable WHERE key = 'sowInsertion'";
const sowFallAmount =
    "SELECT week, month, year, day, COUNT(DISTINCT AnmID) as amount FROM ParamsTable WHERE key = 'fallReason'";
const sowSellAmount =
    "SELECT week, month, year, day, COUNT(DISTINCT AnmID) as amount FROM ParamsTable WHERE key = 'sellAge'";

const currentAmount =
    "SELECT group_nr, SUM(CASE WHEN key IN ('weanedInsertedCnt', 'giltInsertedCnt', 'finisherInsertedCnt', 'weanerTransferredTo', 'giltTransferredTo', 'finisherTransferredTo') THEN [value] ELSE -[value] END) as amount FROM ParamsTable WHERE key IN ('weanedInsertedCnt', 'giltInsertedCnt', 'finisherInsertedCnt', 'weanedPigDeathCnt', 'giltPigDeathCnt', 'finisherPigDeathCnt', 'weanerTransferredTo', 'giltTransferredTo', 'finisherTransferredTo', 'weanerTransferredFrom', 'giltTransferredFrom', 'finisherTransferredFrom', 'weanedPigSellCnt', 'giltPigSellCnt', 'finisherPigSellCnt')";
const generalInserted =
    "SELECT week, month, year, day, group_nr, SUM([value]) as amount FROM ParamsTable WHERE key IN ('weanedInsertedCnt', 'giltInsertedCnt', 'finisherInsertedCnt')";
const generalFallAmount =
    "SELECT week, month, year, day, group_nr, SUM([value]) as amount FROM ParamsTable WHERE key IN ('weanedPigDeathCnt', 'giltPigDeathCnt', 'finisherPigDeathCnt')";
const generalFallWeight = createWeightQuery(
    ["weanedPigDeathWeight", "giltPigDeathWeight", "finisherPigDeathWeight"],
    ["weanedPigDeathCnt", "giltPigDeathCnt", "finisherPigDeathCnt"]
);
const generalMortality = createMortalityQuery(
    ["weanedPigDeathCnt", "giltPigDeathCnt", "finisherPigDeathCnt"],
    [
        "weanedInsertedCnt",
        "weanerTransferredTo",
        "giltInsertedCnt",
        "giltTransferredTo",
        "finisherInsertedCnt",
        "finisherTransferredTo",
    ],
    ["weanerTransferredFrom", "giltTransferredFrom", "finisherTransferredFrom"]
);
const generalSellAmount =
    "SELECT week, month, year, day, group_nr, SUM([value]) as amount FROM ParamsTable WHERE key IN ('weanedPigSellCnt', 'giltPigSellCnt', 'finisherPigSellCnt')";
const generalSellWeight = createWeightQuery(
    ["weanedPigSellWeight", "giltPigSellWeight", "finisherPigSellWeight"],
    ["weanedPigSellCnt", "giltPigSellCnt", "finisherPigSellCnt"]
);
const generalSellWeightTotal =
    "SELECT week, month, year, day, group_nr, SUM([value]) as weight FROM ParamsTable WHERE key IN ('weanedPigSellWeightTotal', 'giltSellWeightTotal', 'finisherPigSellWeightTotal')";
const generalReclassified =
    "SELECT week, month, year, day, group_nr, SUM([value]) as amount FROM ParamsTable WHERE key IN ('weanerReclassified', 'giltReclassified', 'finisherReclassified')";
const generalTransferredTo =
    "SELECT week, month, year, day, group_nr, SUM([value]) as amount FROM ParamsTable WHERE key IN ('weanerTransferredTo', 'giltTransferredTo', 'finisherTransferredTo')";
const generalTransferredFrom =
    "SELECT week, month, year, day, group_nr, SUM([value]) as amount FROM ParamsTable WHERE key IN ('weanerTransferredFrom', 'giltTransferredFrom', 'finisherTransferredFrom')";

const pigletInserted =
    "SELECT week, month, year, day, group_nr, SUM([value]) as amount FROM ParamsTable WHERE key = 'weanedInsertedCnt'";
const pigletFallAmount =
    "SELECT week, month, year, day, group_nr, SUM([value]) as amount FROM ParamsTable WHERE key = 'weanedPigDeathCnt'";
const pigletFallWeight = createWeightQuery(
    ["weanedPigDeathWeight"],
    ["weanedPigDeathCnt"]
);
const pigletSellAmount =
    "SELECT week, month, year, day, group_nr, SUM([value]) as amount FROM ParamsTable WHERE key = 'weanedPigSellCnt'";
const pigletSellWeight = createWeightQuery(
    ["weanedPigSellWeight"],
    ["weanedPigSellCnt"]
);
const pigletSellWeightTotal =
    "SELECT week, month, year, day, group_nr, SUM([value]) as weight FROM ParamsTable WHERE key = 'weanedPigSellWeightTotal'";
const pigletMortality = createMortalityQuery(
    ["weanedPigDeathCnt"],
    ["weanedInsertedCnt", "weanerTransferredTo"],
    ["weanerTransferredFrom"]
);
const pigletReclassified =
    "SELECT week, month, year, day, group_nr, SUM([value]) as amount FROM ParamsTable WHERE key = 'weanerReclassified'";
const pigletTransferredTo =
    "SELECT week, month, year, day, group_nr, SUM([value]) as amount FROM ParamsTable WHERE key = 'weanerTransferredTo'";
const pigletTransferredFrom =
    "SELECT week, month, year, day, group_nr, SUM([value]) as amount FROM ParamsTable WHERE key = 'weanerTransferredFrom'";
const pigletGain =
    "SELECT group_nr, FIRST([value]) as gain FROM ParamsTable WHERE key = 'weanerPigGain'";
const timeOnPigletHouse =
    "SELECT group_nr, LAST([value]) as age FROM ParamsTable WHERE key = 'weanedPigPigletHouseAge'";

const giltInserted =
    "SELECT week, month, year, day, group_nr, SUM([value]) as amount FROM ParamsTable WHERE key = 'giltInsertedCnt'";
const giltFallAmount =
    "SELECT week, month, year, day, group_nr, SUM([value]) as amount FROM ParamsTable WHERE key = 'giltPigDeathCnt'";
const giltFallWeight = createWeightQuery(
    ["giltPigDeathWeight"],
    ["giltPigDeathCnt"]
);
const giltSellAmount =
    "SELECT week, month, year, day, group_nr, SUM([value]) as amount FROM ParamsTable WHERE key = 'giltPigSellCnt'";
const giltSellWeight = createWeightQuery(
    ["giltPigSellWeight"],
    ["giltPigSellCnt"]
);
const giltSellWeightTotal =
    "SELECT week, month, year, day, group_nr, SUM([value]) as weight FROM ParamsTable WHERE key = 'giltSellWeightTotal'";
const giltMortality = createMortalityQuery(
    ["giltPigDeathCnt"],
    ["giltInsertedCnt", "giltTransferredTo"],
    ["giltTransferredFrom"]
);
const giltReclassified =
    "SELECT week, month, year, day, group_nr, SUM([value]) as amount FROM ParamsTable WHERE key = 'giltReclassified'";
const giltTransferredTo =
    "SELECT week, month, year, day, group_nr, SUM([value]) as amount FROM ParamsTable WHERE key = 'giltTransferredTo'";
const giltTransferredFrom =
    "SELECT week, month, year, day, group_nr, SUM([value]) as amount FROM ParamsTable WHERE key = 'giltTransferredFrom'";

const pigletReclassifiedToPorker =
    "SELECT week, month, year, day, group_nr, SUM([value]) as amount FROM ParamsTable WHERE key = 'pigletReclassifyToPorker'";
const porkerInserted =
    "SELECT week, month, year, day, group_nr, SUM([value]) as amount FROM ParamsTable WHERE key = 'finisherInsertedCnt'";
const porkerFallAmount =
    "SELECT week, month, year, day, group_nr, SUM([value]) as amount FROM ParamsTable WHERE key = 'finisherPigDeathCnt'";
const porkerFallWeight = createWeightQuery(
    ["finisherPigDeathWeight"],
    ["finisherPigDeathCnt"]
);
const porkerSellAmount =
    "SELECT week, month, year, day, group_nr, SUM([value]) as amount FROM ParamsTable WHERE key = 'finisherPigSellCnt'";
const porkerSellWeight = createWeightQuery(
    ["finisherPigSellWeight"],
    ["finisherPigSellCnt"]
);
const porkerSellWeightTotal =
    "SELECT week, month, year, day, group_nr, SUM([value]) as weight FROM ParamsTable WHERE key = 'finisherPigSellWeightTotal'";
const porkerMortality = createMortalityQuery(
    ["finisherPigDeathCnt"],
    [
        "finisherInsertedCnt",
        "pigletReclassifyToPorker",
        "finisherTransferredTo",
    ],
    ["finisherTransferredFrom"]
);
const porkerReclassified =
    "SELECT week, month, year, day, group_nr, SUM([value]) as amount FROM ParamsTable WHERE key = 'finisherReclassified'";
const porkerTransferredTo =
    "SELECT week, month, year, day, group_nr, SUM([value]) as amount FROM ParamsTable WHERE key = 'finisherTransferredTo'";
const porkerTransferredFrom =
    "SELECT week, month, year, day, group_nr, SUM([value]) as amount FROM ParamsTable WHERE key = 'finisherTransferredFrom'";
const porkerGain =
    "SELECT group_nr, FIRST([value]) as gain FROM ParamsTable WHERE key = 'finisherPigGain'";
const timeOnPorkHouse =
    "SELECT group_nr, LAST([value]) as age FROM ParamsTable WHERE key = 'finisherPigPorkHouseAge'";

export const resultSelects = {
    //reprodukcja
    inseminatedGiltsAmount,
    inseminatedSowsAmount,
    inseminatedAmount,
    giltsInseminationEffectiveness,
    sowsInseminationEffectiveness,
    inseminationEffectiveness,
    usgAmount,
    farrowingSows,
    repetitionGiltsAmount,
    repetitionSowsAmount,
    repetitionAmount,
    averageRepetitionTime,
    averageSeparationToInseminationDays,
    retention,
    // wyproszenie
    expectedFarrowing,
    farrowedAmount,
    shiftFarrowDay,
    giltsFarrowingEffectiveness,
    sowsFarrowingEffectiveness,
    farrowingEffectiveness,
    bornPiglets,
    averageBornPigletsForSow,
    aliveBornPiglets,
    aliveBornPigletsForSow,
    weakBornPiglets,
    weakBornPigletsForSow,
    weakToAlivePigletsRatio,
    mummyBornPiglets,
    mummyBornPigletsForSow,
    deadBornPiglets,
    deadBornPigletsForSow,
    averagePigletWeight,
    numberOfPigletBySow,
    fostering,
    // odsad
    separatedLitters,
    separatedPiglets,
    separatedPigletForSow,
    // separatedLitterWeight,
    separatedWeight,
    averageSeparatedPigletWeight,
    pigletsMortality,
    deadPiglets,
    deadPigletsForSow,
    lactationDays,
    separationsToBabyRoom,
    separationsToPigletHouse,
    weanedPerFarrowing,
    culledSows,
    // babyroom
    separationsFromBabyRoom,
    babyRoomFall,
    // inwentarz locha/maciora
    sowInsertionAmount,
    sowFallAmount,
    sowSellAmount,
    // inwentarz ogolne tucznik/warchlak
    currentAmount,
    generalInserted,
    generalFallAmount,
    generalFallWeight,
    generalMortality,
    generalSellAmount,
    generalSellWeight,
    generalReclassified,
    generalTransferredTo,
    generalTransferredFrom,
    generalSellWeightTotal,
    // inwentarz warchlak
    pigletInserted,
    pigletFallAmount,
    pigletFallWeight,
    pigletSellAmount,
    pigletSellWeight,
    pigletMortality,
    pigletReclassified,
    pigletTransferredTo,
    pigletTransferredFrom,
    pigletGain,
    pigletSellWeightTotal,
    timeOnPigletHouse,
    // inwentarz tucznik
    pigletReclassifiedToPorker,
    porkerInserted,
    porkerFallAmount,
    porkerFallWeight,
    porkerSellAmount,
    porkerSellWeight,
    porkerMortality,
    porkerReclassified,
    porkerTransferredTo,
    porkerTransferredFrom,
    porkerGain,
    porkerSellWeightTotal,
    timeOnPorkHouse,
    // inwentarz loszka
    giltInserted,
    giltFallAmount,
    giltFallWeight,
    giltSellAmount,
    giltSellWeight,
    giltMortality,
    giltReclassified,
    giltTransferredTo,
    giltTransferredFrom,
    giltSellWeightTotal,
};

function createInseminationEffectivenessSummaryQuery(cycleType = undefined) {
    let where = "";
    if (cycleType !== undefined) {
        where = `AND cycle ${cycleType} CONST('FIRST_CYCLE_INDEX')`;
    }
    return `SELECT VALUE SUM(positive) / SUM(animals) * 100 FROM (SELECT week, month, year, day, group_nr, COUNT(DISTINCT CASE WHEN (key = 'usgResult' AND [value] = 1) OR (key = 'bornPiglet') THEN AnmID END) as positive, COUNT(DISTINCT AnmID) as animals FROM ParamsTable WHERE dataEv <= CAST(DATE(NOW()) AS NUMBER) - (1000 * 60 * 60 * 24 * CONST('TIME_FROM_INSEMINATION_TO_PREGNANCY')) AND key IN ('boar', 'usgResult', 'bornPiglets') ${where} {{END_QUERY}})`;
}

const giltsEffectivenessSummary =
    createInseminationEffectivenessSummaryQuery("=");
const sowsEffectivenessSummary =
    createInseminationEffectivenessSummaryQuery(">");
const inseminationEffectivenessSummary =
    createInseminationEffectivenessSummaryQuery();

const giltsFarrowingEffectivenessSummary =
    "SELECT VALUE SUM(farrowed) / SUM(animals) * 100 FROM (SELECT COUNT(DISTINCT CASE WHEN key = 'bornPiglet' THEN AnmID END) as farrowed, COUNT(DISTINCT AnmID) as animals, MAX(CASE WHEN key = 'expectedFarrowing' THEN [value] END) as expectedFarrowing FROM ParamsTable WHERE cycle = CONST('FIRST_CYCLE_INDEX') {{END_QUERY}}) WHERE expectedFarrowing <= 0";
const sowsFarrowingEffectivenessSummary =
    "SELECT VALUE SUM(farrowed) / SUM(animals) * 100 FROM (SELECT COUNT(DISTINCT CASE WHEN key = 'bornPiglet' THEN AnmID END) as farrowed, COUNT(DISTINCT AnmID) as animals, MAX(CASE WHEN key = 'expectedFarrowing' THEN [value] END) as expectedFarrowing FROM ParamsTable WHERE cycle > CONST('FIRST_CYCLE_INDEX') {{END_QUERY}}) WHERE expectedFarrowing <= 0";
const farrowingEffectivenessSummary =
    "SELECT VALUE SUM(farrowed) / SUM(animals) * 100 FROM (SELECT COUNT(DISTINCT CASE WHEN key = 'bornPiglet' THEN AnmID END) as farrowed, COUNT(DISTINCT AnmID) as animals, MAX(CASE WHEN key = 'expectedFarrowing' THEN [value] END) as expectedFarrowing FROM ParamsTable {{END_QUERY}}) WHERE expectedFarrowing <= 0";

const averageBornPigletsForSowSummary =
    "SELECT VALUE SUM(born) / SUM(animals) FROM (SELECT SUM([value]) as born, COUNT(DISTINCT AnmID) as animals FROM ParamsTable WHERE key = 'bornPiglet' {{END_QUERY}})";
const aliveBornPigletsForSowSummary =
    "SELECT VALUE SUM(born) / SUM(animals) FROM (SELECT SUM([value]) as born, COUNT(DISTINCT AnmID) as animals FROM ParamsTable WHERE key = 'liveBornPiglet' {{END_QUERY}})";
const weakBornPigletsForSowSummary =
    "SELECT VALUE SUM(born) / SUM(animals) FROM (SELECT SUM([value]) as born, COUNT(DISTINCT AnmID) as animals FROM ParamsTable WHERE key = 'weakBornPiglet' {{END_QUERY}})";
const weakToAlivePigletsRatioSummary =
    "SELECT VALUE SUM(weak) / SUM(alive) * 100 FROM (SELECT SUM(CASE WHEN key = 'weakBornPiglet' THEN [value] END) as weak, SUM(CASE WHEN key = 'liveBornPiglet' THEN [value] END) as alive FROM ParamsTable {{END_QUERY}})";
const mummyBornPigletsForSowSummary =
    "SELECT VALUE SUM(born) / SUM(animals) FROM (SELECT SUM([value]) as born, COUNT(DISTINCT AnmID) as animals FROM ParamsTable WHERE key = 'mummyBornPiglet' {{END_QUERY}})";
const deadBornPigletsForSowSummary =
    "SELECT VALUE SUM(born) / SUM(animals) FROM (SELECT SUM([value]) as born, COUNT(DISTINCT AnmID) as animals FROM ParamsTable WHERE key = 'deadBornPiglet' {{END_QUERY}})";

const separatedPigletForSowSummary =
    "SELECT VALUE SUM(weaned) / SUM(animals) FROM (SELECT SUM([value]) as weaned, COUNT(AnmID) as animals FROM ParamsTable WHERE key = 'weanedPiglet' {{END_QUERY}})";

export const resultsSummary = {
    giltsInseminationEffectiveness: giltsEffectivenessSummary,
    sowsInseminationEffectiveness: sowsEffectivenessSummary,
    inseminationEffectiveness: inseminationEffectivenessSummary,

    giltsFarrowingEffectiveness: giltsFarrowingEffectivenessSummary,
    sowsFarrowingEffectiveness: sowsFarrowingEffectivenessSummary,
    farrowingEffectiveness: farrowingEffectivenessSummary,

    averageBornPigletsForSow: averageBornPigletsForSowSummary,
    aliveBornPigletsForSow: aliveBornPigletsForSowSummary,
    weakBornPigletsForSow: weakBornPigletsForSowSummary,
    weakToAlivePigletsRatio: weakToAlivePigletsRatioSummary,
    mummyBornPigletsForSow: mummyBornPigletsForSowSummary,
    deadBornPigletsForSow: deadBornPigletsForSowSummary,
    separatedPigletForSow: separatedPigletForSowSummary,
};

export const allResults = {
    inseminatedGiltsAmount: {
        name: "planTypes.inseminatedRenovationSows",
        sqlKey: "inseminatedGiltsAmount",
        valueKey: "inseminated",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    inseminatedSowsAmount: {
        name: "planTypes.inseminatedSows",
        sqlKey: "inseminatedSowsAmount",
        valueKey: "inseminated",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    inseminatedAmount: {
        name: "planTypes.inseminatedAllSows",
        sqlKey: "inseminatedAmount",
        valueKey: "inseminated",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    usgAmount: {
        name: "planTypes.allUsgs",
        sqlKey: "usgAmount",
        valueKey: "amount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    giltsInseminationEffectiveness: {
        name: "planTypes.giltsInseminationEffectiveness",
        sqlKey: "giltsInseminationEffectiveness",
        valueKey: "effectiveness",
        type: "float",
        unit: ResultsGridUnitTypesV2.PERCENT,
        summaryType: ResultSummaryTypes.CUSTOM,
    },
    sowsInseminationEffectiveness: {
        name: "planTypes.sowsInseminationEffectiveness",
        sqlKey: "sowsInseminationEffectiveness",
        valueKey: "effectiveness",
        type: "float",
        unit: ResultsGridUnitTypesV2.PERCENT,
        summaryType: ResultSummaryTypes.CUSTOM,
    },
    inseminationEffectiveness: {
        name: "planTypes.inseminationEffectiveness",
        sqlKey: "inseminationEffectiveness",
        valueKey: "effectiveness",
        type: "float",
        unit: ResultsGridUnitTypesV2.PERCENT,
        summaryType: ResultSummaryTypes.CUSTOM,
    },
    farrowingSows: {
        name: "planTypes.parturitionedSowsAmount",
        sqlKey: "farrowingSows",
        valueKey: "amount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    repetitionGiltsAmount: {
        name: "planTypes.repetitionsForRenovationSows",
        sqlKey: "repetitionGiltsAmount",
        valueKey: "repetitions",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    repetitionSowsAmount: {
        name: "planTypes.repetitionsForSows",
        sqlKey: "repetitionSowsAmount",
        valueKey: "repetitions",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    repetitionAmount: {
        name: "planTypes.numberOfRepetitions",
        sqlKey: "repetitionAmount",
        valueKey: "repetitions",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    averageRepetitionTime: {
        name: "planTypes.avgBrakeFromInsToRep",
        sqlKey: "averageRepetitionTime",
        valueKey: "repetitionTime",
        type: "float",
        unit: ResultsGridUnitTypesV2.AVG_DAYS,
        summaryType: ResultSummaryTypes.AVG,
        doNotGroupSummary: true,
    },
    averageSeparationToInseminationDays: {
        name: "planTypes.avgBrakeFromSepToIns",
        sqlKey: "averageSeparationToInseminationDays",
        valueKey: "days",
        type: "float",
        unit: ResultsGridUnitTypesV2.AVG_DAYS,
        summaryType: ResultSummaryTypes.AVG,
        doNotGroupSummary: true,
    },
    retention: {
        name: "planTypes.retention",
        sqlKey: "retention",
        valueKey: "retention",
        type: "float",
        unit: ResultsGridUnitTypesV2.PERCENT,
        summaryType: ResultSummaryTypes.AVG,
        doNotGroupSummary: true,
    },
    expectedFarrowing: {
        name: "planTypes.expectedParturitions",
        sqlKey: "expectedFarrowing",
        valueKey: "amount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    farrowedAmount: {
        name: "planTypes.parturitionedSows",
        sqlKey: "farrowedAmount",
        valueKey: "farrowedAmount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    shiftFarrowDay: {
        name: "planTypes.parturitionDiffDays",
        sqlKey: "shiftFarrowDay",
        valueKey: "shiftFarrowDay",
        type: "float",
        unit: ResultsGridUnitTypesV2.AVG_DAYS,
        summaryType: ResultSummaryTypes.AVG,
        doNotGroupSummary: true,
    },
    giltsFarrowingEffectiveness: {
        name: "planTypes.giltsFarrowingEffectiveness",
        sqlKey: "giltsFarrowingEffectiveness",
        valueKey: "effectiveness",
        type: "float",
        unit: ResultsGridUnitTypesV2.PERCENT,
        summaryType: ResultSummaryTypes.CUSTOM,
    },
    sowsFarrowingEffectiveness: {
        name: "planTypes.sowsFarrowingEffectiveness",
        sqlKey: "sowsFarrowingEffectiveness",
        valueKey: "effectiveness",
        type: "float",
        unit: ResultsGridUnitTypesV2.PERCENT,
        summaryType: ResultSummaryTypes.CUSTOM,
    },
    farrowingEffectiveness: {
        name: "planTypes.parturitionEffectiveness",
        sqlKey: "farrowingEffectiveness",
        valueKey: "effectiveness",
        type: "float",
        unit: ResultsGridUnitTypesV2.PERCENT,
        summaryType: ResultSummaryTypes.CUSTOM,
    },
    bornPiglets: {
        name: "planTypes.allBornPiglets",
        sqlKey: "bornPiglets",
        valueKey: "piglets",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    averageBornPigletsForSow: {
        name: "planTypes.allBornPigletsPerSow",
        sqlKey: "averageBornPigletsForSow",
        valueKey: "average",
        type: "float",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.CUSTOM,
    },
    aliveBornPiglets: {
        name: "planTypes.bornHealthyPiglets",
        sqlKey: "aliveBornPiglets",
        valueKey: "piglets",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    aliveBornPigletsForSow: {
        name: "planTypes.bornHealthyPigletsPerSow",
        sqlKey: "aliveBornPigletsForSow",
        valueKey: "average",
        type: "float",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.CUSTOM,
    },
    weakBornPiglets: {
        name: "planTypes.bornWeakPiglets",
        sqlKey: "weakBornPiglets",
        valueKey: "piglets",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    weakBornPigletsForSow: {
        name: "planTypes.bornWeakPigletsPerSow",
        sqlKey: "weakBornPigletsForSow",
        valueKey: "average",
        type: "float",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.CUSTOM,
    },
    weakToAlivePigletsRatio: {
        name: "planTypes.percentageOfWeakPigletsBornRelativeToHealthy",
        sqlKey: "weakToAlivePigletsRatio",
        valueKey: "ratio",
        type: "float",
        unit: ResultsGridUnitTypesV2.PERCENT,
        summaryType: ResultSummaryTypes.CUSTOM,
    },
    mummyBornPiglets: {
        name: "planTypes.bornMummyPiglets",
        sqlKey: "mummyBornPiglets",
        valueKey: "piglets",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    mummyBornPigletsForSow: {
        name: "planTypes.bornMummyPigletsPerSow",
        sqlKey: "mummyBornPigletsForSow",
        valueKey: "average",
        type: "float",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.CUSTOM,
    },
    deadBornPiglets: {
        name: "planTypes.bornDeadPiglets",
        sqlKey: "deadBornPiglets",
        valueKey: "piglets",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    deadBornPigletsForSow: {
        name: "planTypes.bornDeadPigletsPerSow",
        sqlKey: "deadBornPigletsForSow",
        valueKey: "average",
        type: "float",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.CUSTOM,
    },
    averagePigletWeight: {
        name: "planTypes.averageBornPigletsWeight",
        sqlKey: "averagePigletWeight",
        valueKey: "average",
        type: "weight",
        unit: "WEIGHT_MEDIUM",
        summaryType: ResultSummaryTypes.AVG,
        doNotGroupSummary: true,
    },
    numberOfPigletBySow: {
        name: "numberOfPigletsBySow",
        sqlKey: "numberOfPigletBySow",
        valueKey: "amount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    fostering: {
        name: "controlLists.mainView.fosteringTitle",
        sqlKey: "fostering",
        valueKey: "fostering",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    separatedLitters: {
        name: "planTypes.separatedSows",
        sqlKey: "separatedLitters",
        valueKey: "amount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    separationsToPigletHouse: {
        name: "modals.editSowCycle.separationToPiglethouse",
        sqlKey: "separationsToPigletHouse",
        valueKey: "weaned",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    separatedPiglets: {
        name: "planTypes.amountOfSeparatedPiglets",
        sqlKey: "separatedPiglets",
        valueKey: "amount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    separationsToBabyRoom: {
        name: "separatedToBabyRoom",
        sqlKey: "separationsToBabyRoom",
        valueKey: "weaned",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    separatedPigletForSow: {
        name: "numberOfSeparatedPerSeparation",
        sqlKey: "separatedPigletForSow",
        valueKey: "average",
        type: "float",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.CUSTOM,
    },
    weanedPerFarrowing: {
        name: "numberOfSeparatedPerFarrowing",
        sqlKey: "weanedPerFarrowing",
        valueKey: "weaned",
        type: "float",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.AVG,
    },
    separatedWeight: {
        name: "planTypes.litterWeight",
        sqlKey: "separatedWeight",
        valueKey: "weight",
        type: "weight",
        unit: "WEIGHT_MEDIUM",
        summaryType: ResultSummaryTypes.SUM,
    },
    averageSeparatedPigletWeight: {
        name: "planTypes.averageWeightOfSeparatedPiglet",
        sqlKey: "averageSeparatedPigletWeight",
        valueKey: "weight",
        type: "weight",
        unit: "WEIGHT_MEDIUM",
        summaryType: ResultSummaryTypes.AVG,
        doNotGroupSummary: true,
    },
    pigletsMortality: {
        name: "planTypes.pigletsMortality",
        sqlKey: "pigletsMortality",
        valueKey: "mortality",
        type: "float",
        unit: ResultsGridUnitTypesV2.PERCENT,
        summaryType: ResultSummaryTypes.AVG,
        doNotGroupSummary: true,
    },
    deadPiglets: {
        name: "planTypes.numberOfFallenPigs",
        sqlKey: "deadPiglets",
        valueKey: "dead",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    deadPigletsForSow: {
        name: "planTypes.numberOfFallenPigsForSow",
        sqlKey: "deadPigletsForSow",
        valueKey: "dead",
        type: "float",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.AVG,
        doNotGroupSummary: true,
    },
    lactationDays: {
        name: "sowResults.lactationDays",
        sqlKey: "lactationDays",
        valueKey: "lactationDays",
        type: "float",
        unit: ResultsGridUnitTypesV2.AVG_DAYS,
        summaryType: ResultSummaryTypes.AVG,
        doNotGroupSummary: true,
    },
    culledSows: {
        name: "culledSows",
        sqlKey: "culledSows",
        valueKey: "animals",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    //baby room
    separationsFromBabyRoom: {
        name: "separatedFromBabyRoom",
        sqlKey: "separationsFromBabyRoom",
        valueKey: "amount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    babyRoomFall: {
        name: "babyRoomFall",
        sqlKey: "babyRoomFall",
        valueKey: "amount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    // inwentarz locha/maciora
    sowInsertionAmount: {
        name: "planTypes.insertedSows",
        sqlKey: "sowInsertionAmount",
        valueKey: "amount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    sowFallAmount: {
        name: "planTypes.numberOfFallenSows",
        sqlKey: "sowFallAmount",
        valueKey: "amount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    sowSellAmount: {
        name: "planTypes.numberOfSowsSold",
        sqlKey: "sowSellAmount",
        valueKey: "amount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    // inwentarz ogolne tucznik/warchlak
    currentAmount: {
        name: "currentAnimalAmount",
        sqlKey: "currentAmount",
        valueKey: "amount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    generalInserted: {
        name: "animalsInserted",
        sqlKey: "generalInserted",
        valueKey: "amount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    generalFallAmount: {
        name: "numberOfFallenAnimals",
        sqlKey: "generalFallAmount",
        valueKey: "amount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    generalFallWeight: {
        name: "averageWeightOfDeadAnimals",
        sqlKey: "generalFallWeight",
        valueKey: "weight",
        type: "weight",
        unit: ResultsGridUnitTypesV2.WEIGHT,
        summaryType: ResultSummaryTypes.AVG,
    },
    generalMortality: {
        name: "animalMortality",
        sqlKey: "generalMortality",
        valueKey: "mortality",
        type: "float",
        unit: ResultsGridUnitTypesV2.PERCENT,
        summaryType: ResultSummaryTypes.AVG,
        doNotGroupSummary: true,
    },
    generalSellAmount: {
        name: "numberOfSoldAnimals",
        sqlKey: "generalSellAmount",
        valueKey: "amount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    generalSellWeight: {
        name: "averageWeightOfSoldAnimals",
        sqlKey: "generalSellWeight",
        valueKey: "weight",
        type: "weight",
        unit: ResultsGridUnitTypesV2.WEIGHT,
        summaryType: ResultSummaryTypes.AVG,
    },
    generalSellWeightTotal: {
        name: "weightOfSoldAnimals",
        sqlKey: "generalSellWeightTotal",
        valueKey: "weight",
        type: "bigWeight",
        unit: ResultsGridUnitTypesV2.BIG_WEIGHT,
        summaryType: ResultSummaryTypes.AVG,
    },
    generalReclassified: {
        name: "reclassifiedAnimals",
        sqlKey: "generalReclassified",
        valueKey: "amount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    generalTransferredTo: {
        name: "animalsTransferredToGroup",
        sqlKey: "generalTransferredTo",
        valueKey: "amount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    generalTransferredFrom: {
        name: "animalsTransferredToOtherGroup",
        sqlKey: "generalTransferredFrom",
        valueKey: "amount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    // inwentarz warchlak
    pigletInserted: {
        name: "planTypes.insertedWeaners",
        sqlKey: "pigletInserted",
        valueKey: "amount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    pigletFallAmount: {
        name: "planTypes.numberOfFallenPiglets",
        sqlKey: "pigletFallAmount",
        valueKey: "amount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    pigletFallWeight: {
        name: "planTypes.weightOfFallenPiglets",
        sqlKey: "pigletFallWeight",
        valueKey: "weight",
        type: "weight",
        unit: ResultsGridUnitTypesV2.WEIGHT,
        summaryType: ResultSummaryTypes.AVG,
    },
    pigletSellAmount: {
        name: "planTypes.numberOfPigletsSold",
        sqlKey: "pigletSellAmount",
        valueKey: "amount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    pigletSellWeight: {
        name: "planTypes.averagePigletSellWeight",
        sqlKey: "pigletSellWeight",
        valueKey: "weight",
        type: "weight",
        unit: ResultsGridUnitTypesV2.WEIGHT,
        summaryType: ResultSummaryTypes.AVG,
    },
    pigletSellWeightTotal: {
        name: "weightOfSoldWeaners",
        sqlKey: "pigletSellWeightTotal",
        valueKey: "weight",
        type: "bigWeight",
        unit: ResultsGridUnitTypesV2.BIG_WEIGHT,
        summaryType: ResultSummaryTypes.AVG,
    },
    pigletMortality: {
        name: "planTypes.weanerMortality",
        sqlKey: "pigletMortality",
        valueKey: "mortality",
        type: "float",
        unit: ResultsGridUnitTypesV2.PERCENT,
        summaryType: ResultSummaryTypes.AVG,
        doNotGroupSummary: true,
    },
    pigletReclassified: {
        name: "planTypes.reclassifiedPiglets",
        sqlKey: "pigletReclassified",
        valueKey: "amount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    pigletTransferredTo: {
        name: "weanersTransferredGroup",
        sqlKey: "pigletTransferredTo",
        valueKey: "amount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    pigletTransferredFrom: {
        name: "weanersTransferredToOtherGroup",
        sqlKey: "pigletTransferredFrom",
        valueKey: "amount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    pigletGain: {
        name: "weanerGain",
        sqlKey: "pigletGain",
        valueKey: "gain",
        type: "weight",
        unit: ResultsGridUnitTypesV2.WEIGHT,
        summaryType: ResultSummaryTypes.AVG,
    },
    timeOnPigletHouse: {
        name: "Liczba dni na warchlakarni",
        sqlKey: "timeOnPigletHouse",
        valueKey: "age",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.AVG,
        disableDetails: true,
    },
    // inwentarz tucznik
    pigletReclassifiedToPorker: {
        name: "planTypes.pigletReclassifiedToPorker",
        sqlKey: "pigletReclassifiedToPorker",
        valueKey: "amount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    porkerInserted: {
        name: "planTypes.insertedPorkers",
        sqlKey: "porkerInserted",
        valueKey: "amount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    porkerFallAmount: {
        name: "planTypes.numberOfFallenPorkers",
        sqlKey: "porkerFallAmount",
        valueKey: "amount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    porkerFallWeight: {
        name: "planTypes.weightOfFallenPorkers",
        sqlKey: "porkerFallWeight",
        valueKey: "weight",
        type: "weight",
        unit: ResultsGridUnitTypesV2.WEIGHT,
        summaryType: ResultSummaryTypes.AVG,
    },
    porkerSellAmount: {
        name: "planTypes.numberOfPorkersSold",
        sqlKey: "porkerSellAmount",
        valueKey: "amount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    porkerSellWeight: {
        name: "planTypes.averagePorkerSellWeight",
        sqlKey: "porkerSellWeight",
        valueKey: "weight",
        type: "weight",
        unit: ResultsGridUnitTypesV2.WEIGHT,
        summaryType: ResultSummaryTypes.AVG,
    },
    porkerSellWeightTotal: {
        name: "weightOfFinisherSold",
        sqlKey: "porkerSellWeightTotal",
        valueKey: "weight",
        type: "bigWeight",
        unit: ResultsGridUnitTypesV2.BIG_WEIGHT,
        summaryType: ResultSummaryTypes.AVG,
    },
    porkerMortality: {
        name: "planTypes.porkerMortality",
        sqlKey: "porkerMortality",
        valueKey: "mortality",
        type: "float",
        unit: ResultsGridUnitTypesV2.PERCENT,
        summaryType: ResultSummaryTypes.AVG,
        doNotGroupSummary: true,
    },
    porkerReclassified: {
        name: "finisherReclassified",
        sqlKey: "porkerReclassified",
        valueKey: "amount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    porkerTransferredTo: {
        name: "finisherTransferredToGroup",
        sqlKey: "porkerTransferredTo",
        valueKey: "amount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    porkerTransferredFrom: {
        name: "finisherTransferredToOtherGroup",
        sqlKey: "porkerTransferredFrom",
        valueKey: "amount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    porkerGain: {
        name: "finisherGain",
        sqlKey: "porkerGain",
        valueKey: "gain",
        type: "weight",
        unit: ResultsGridUnitTypesV2.WEIGHT,
        summaryType: ResultSummaryTypes.AVG,
    },
    timeOnPorkHouse: {
        name: "Liczba dni na tuczarni",
        sqlKey: "timeOnPorkHouse",
        valueKey: "age",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.AVG,
        disableDetails: true,
    },
    // inwentarz loszka
    giltInserted: {
        name: "giltInserted",
        sqlKey: "giltInserted",
        valueKey: "amount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    giltFallAmount: {
        name: "planTypes.numberOfFallenRenovationSows",
        sqlKey: "giltFallAmount",
        valueKey: "amount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    giltFallWeight: {
        name: "averageWeightOfDeadGilts",
        sqlKey: "giltFallWeight",
        valueKey: "weight",
        type: "weight",
        unit: ResultsGridUnitTypesV2.WEIGHT,
        summaryType: ResultSummaryTypes.AVG,
    },
    giltSellAmount: {
        name: "planTypes.numberOfRenovationSowsSold",
        sqlKey: "giltSellAmount",
        valueKey: "amount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    giltSellWeight: {
        name: "averageWeightOfSoldGilts",
        sqlKey: "giltSellWeight",
        valueKey: "weight",
        type: "weight",
        unit: ResultsGridUnitTypesV2.WEIGHT,
        summaryType: ResultSummaryTypes.AVG,
    },
    giltSellWeightTotal: {
        name: "weightOfSoldGilts",
        sqlKey: "giltSellWeightTotal",
        valueKey: "weight",
        type: "bigWeight",
        unit: ResultsGridUnitTypesV2.BIG_WEIGHT,
        summaryType: ResultSummaryTypes.AVG,
    },
    giltMortality: {
        name: "giltMortality",
        sqlKey: "giltMortality",
        valueKey: "mortality",
        type: "float",
        unit: ResultsGridUnitTypesV2.PERCENT,
        summaryType: ResultSummaryTypes.AVG,
        doNotGroupSummary: true,
    },
    giltReclassified: {
        name: "reclassifiedGilts",
        sqlKey: "giltReclassified",
        valueKey: "amount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    giltTransferredTo: {
        name: "giltsTransferredToGroup",
        sqlKey: "giltTransferredTo",
        valueKey: "amount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
    giltTransferredFrom: {
        name: "giltsTransferredToOtherGroup",
        sqlKey: "giltTransferredFrom",
        valueKey: "amount",
        type: "number",
        unit: ResultsGridUnitTypesV2.PIECES,
        summaryType: ResultSummaryTypes.SUM,
    },
};

export const technologyGroupList = [
    {name: "planTypes.reproduction", isFullWidth: true},
    "inseminatedGiltsAmount",
    "inseminatedSowsAmount",
    "inseminatedAmount",
    "usgAmount",
    "giltsInseminationEffectiveness",
    "sowsInseminationEffectiveness",
    "inseminationEffectiveness",
    "farrowingSows",
    "repetitionGiltsAmount",
    "repetitionSowsAmount",
    "repetitionAmount",
    "averageRepetitionTime",
    "averageSeparationToInseminationDays",
    {name: "planTypes.parturitions", isFullWidth: true},
    "expectedFarrowing",
    "farrowedAmount",
    "shiftFarrowDay",
    "giltsFarrowingEffectiveness",
    "sowsFarrowingEffectiveness",
    "farrowingEffectiveness",
    "bornPiglets",
    "averageBornPigletsForSow",
    "aliveBornPiglets",
    "aliveBornPigletsForSow",
    "weakBornPiglets",
    "weakBornPigletsForSow",
    "weakToAlivePigletsRatio",
    "mummyBornPiglets",
    "mummyBornPigletsForSow",
    "deadBornPiglets",
    "deadBornPigletsForSow",
    "averagePigletWeight",
    "numberOfPigletBySow",
    "fostering",
    {name: "separation", isFullWidth: true},
    "separatedLitters",
    "separationsToPigletHouse",
    "separationsToBabyRoom",
    "separatedPiglets",
    "separatedPigletForSow",
    "weanedPerFarrowing",
    "separatedWeight",
    "averageSeparatedPigletWeight",
    "pigletsMortality",
    "deadPiglets",
    "deadPigletsForSow",
    "lactationDays",
    "culledSows",
    {name: "SType.4", isFullWidth: true},
    "separationsFromBabyRoom",
    "babyRoomFall",
];

export const weekAndMonthList = [
    {name: "animalKind.0", isFullWidth: true},
    "sowInsertionAmount",
    "sowFallAmount",
    "sowSellAmount",
    {name: "animalKind.2", isFullWidth: true},
    "pigletInserted",
    "pigletFallAmount",
    "pigletFallWeight",
    "pigletSellAmount",
    "pigletSellWeight",
    {name: "animalKind.3", isFullWidth: true},
    "pigletReclassifiedToPorker",
    "porkerInserted",
    "porkerFallAmount",
    "porkerFallWeight",
    "porkerSellAmount",
    "porkerSellWeight",
    {name: "animalKind.5", isFullWidth: true},
    "giltInserted",
    "giltFallAmount",
    "giltFallWeight",
    "giltSellAmount",
    "giltSellWeight",
    {name: "planTypes.reproduction", isFullWidth: true},
    "inseminatedGiltsAmount",
    "inseminatedSowsAmount",
    "inseminatedAmount",
    "usgAmount",
    "repetitionGiltsAmount",
    "repetitionSowsAmount",
    "repetitionAmount",
    "retention",
    {name: "planTypes.parturitions", isFullWidth: true},
    "expectedFarrowing",
    "farrowedAmount",
    "shiftFarrowDay",
    "bornPiglets",
    "averageBornPigletsForSow",
    "aliveBornPiglets",
    "aliveBornPigletsForSow",
    "weakBornPiglets",
    "weakBornPigletsForSow",
    "weakToAlivePigletsRatio",
    "mummyBornPiglets",
    "mummyBornPigletsForSow",
    "deadBornPiglets",
    "deadBornPigletsForSow",
    "averagePigletWeight",
    "fostering",
    {name: "separation", isFullWidth: true},
    "separatedLitters",
    "separationsToPigletHouse",
    "separatedPiglets",
    "separationsToBabyRoom",
    "separatedPigletForSow",
    "separatedWeight",
    "averageSeparatedPigletWeight",
    "deadPiglets",
    "culledSows",
    {name: "SType.4", isFullWidth: true},
    "separationsFromBabyRoom",
    "babyRoomFall",
];

export const porkerAndPigletList = [
    {name: "general", isFullWidth: true},
    "currentAmount",
    "generalInserted",
    "generalFallAmount",
    "generalFallWeight",
    "generalMortality",
    "generalSellAmount",
    "generalSellWeight",
    "generalSellWeightTotal",
    "generalReclassified",
    "generalTransferredTo",
    "generalTransferredFrom",
    {name: "animalKind.2", isFullWidth: true},
    "pigletInserted",
    "pigletFallAmount",
    "pigletFallWeight",
    "pigletMortality",
    "pigletSellAmount",
    "pigletSellWeight",
    "pigletSellWeightTotal",
    "pigletReclassified",
    "pigletTransferredTo",
    "pigletTransferredFrom",
    "pigletGain",
    "timeOnPigletHouse",
    {name: "animalKind.5", isFullWidth: true},
    "giltInserted",
    "giltFallAmount",
    "giltFallWeight",
    "giltMortality",
    "giltSellAmount",
    "giltSellWeight",
    "giltSellWeightTotal",
    "giltReclassified",
    "giltTransferredTo",
    "giltTransferredFrom",
    {name: "animalKind.3", isFullWidth: true},
    "pigletReclassifiedToPorker",
    "porkerInserted",
    "porkerFallAmount",
    "porkerFallWeight",
    "porkerMortality",
    "porkerSellAmount",
    "porkerSellWeight",
    "porkerSellWeightTotal",
    "porkerReclassified",
    "porkerTransferredTo",
    "porkerTransferredFrom",
    "porkerGain",
    "timeOnPorkHouse",
];

const farrowedAmountFormula =
    "({{inseminatedGiltsAmount}} + {{inseminatedSowsAmount}}) * ({{farrowingEffectiveness}} / 100)";

const farrowedAmountHTMLFormula =
    "<mo>(</mo><mi>{{inseminatedGiltsAmount}}</mi><mo>+</mo><mi>{{inseminatedSowsAmount}}</mi><mo>)</mo><mo>⋅</mo><mi>{{farrowingEffectiveness}}</mi>";

export const resultTargetList = [
    {name: "animalKind.0", isFullWidth: true},
    {
        name: "planTypes.insertedSows",
        sqlKey: "sowInsertionAmount",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "planTypes.numberOfFallenSows",
        sqlKey: "sowFallAmount",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "planTypes.numberOfSowsSold",
        sqlKey: "sowSellAmount",
        min: 1,
        max: 10,
        default: [1, 10],
    },

    {name: "general", isFullWidth: true},
    {
        name: "currentAnimalAmount",
        sqlKey: "currentAmount",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "animalsInserted",
        sqlKey: "generalInserted",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "numberOfFallenAnimals",
        sqlKey: "generalFallAmount",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "averageWeightOfDeadAnimals",
        sqlKey: "generalFallWeight",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "animalMortality",
        sqlKey: "generalMortality",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "numberOfSoldAnimals",
        sqlKey: "generalSellAmount",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "averageWeightOfSoldAnimals",
        sqlKey: "generalSellWeight",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "weightOfSoldAnimals",
        sqlKey: "generalSellWeightTotal",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "reclassifiedAnimals",
        sqlKey: "generalReclassified",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "animalsTransferredToGroup",
        sqlKey: "generalTransferredTo",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "animalsTransferredToOtherGroup",
        sqlKey: "generalTransferredFrom",
        min: 1,
        max: 10,
        default: [1, 10],
    },

    {name: "animalKind.2", isFullWidth: true},
    {
        name: "planTypes.insertedWeaners",
        sqlKey: "pigletInserted",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "planTypes.numberOfFallenPiglets",
        sqlKey: "pigletFallAmount",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "planTypes.weightOfFallenPiglets",
        sqlKey: "pigletFallWeight",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "planTypes.numberOfPigletsSold",
        sqlKey: "pigletSellAmount",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "planTypes.averagePigletSellWeight",
        sqlKey: "pigletSellWeight",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "weightOfSoldWeaners",
        sqlKey: "pigletSellWeightTotal",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "planTypes.weanerMortality",
        sqlKey: "pigletMortality",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "planTypes.reclassifiedPiglets",
        sqlKey: "pigletReclassified",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "weanersTransferredGroup",
        sqlKey: "pigletTransferredTo",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "weanersTransferredToOtherGroup",
        sqlKey: "pigletTransferredFrom",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "weanerGain",
        sqlKey: "pigletGain",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "timeOnPigletHouse",
        sqlKey: "timeOnPigletHouse",
        min: 1,
        max: 10,
        default: [1, 10],
    },

    {name: "animalKind.5", isFullWidth: true},
    {
        name: "giltInserted",
        sqlKey: "giltInserted",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "planTypes.numberOfFallenRenovationSows",
        sqlKey: "giltFallAmount",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "averageWeightOfDeadGilts",
        sqlKey: "giltFallWeight",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "planTypes.numberOfRenovationSowsSold",
        sqlKey: "giltSellAmount",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "averageWeightOfSoldGilts",
        sqlKey: "giltSellWeight",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "weightOfSoldGilts",
        sqlKey: "giltSellWeightTotal",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "giltMortality",
        sqlKey: "giltMortality",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "reclassifiedGilts",
        sqlKey: "giltReclassified",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "giltsTransferredToGroup",
        sqlKey: "giltTransferredTo",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "giltsTransferredToOtherGroup",
        sqlKey: "giltTransferredFrom",
        min: 1,
        max: 10,
        default: [1, 10],
    },

    {name: "animalKind.3", isFullWidth: true},
    {
        name: "planTypes.pigletReclassifiedToPorker",
        sqlKey: "pigletReclassifiedToPorker",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "planTypes.insertedPorkers",
        sqlKey: "porkerInserted",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "planTypes.numberOfFallenPorkers",
        sqlKey: "porkerFallAmount",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "planTypes.weightOfFallenPorkers",
        sqlKey: "porkerFallWeight",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "planTypes.numberOfPorkersSold",
        sqlKey: "porkerSellAmount",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "planTypes.averagePorkerSellWeight",
        sqlKey: "porkerSellWeight",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "weightOfFinisherSold",
        sqlKey: "porkerSellWeightTotal",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "planTypes.porkerMortality",
        sqlKey: "porkerMortality",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "finisherReclassified",
        sqlKey: "porkerReclassified",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "finisherTransferredToGroup",
        sqlKey: "porkerTransferredTo",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "finisherTransferredToOtherGroup",
        sqlKey: "porkerTransferredFrom",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "finisherGain",
        sqlKey: "porkerGain",
        min: 1,
        max: 10,
        default: [1, 10],
    },
    {
        name: "timeOnPorkHouse",
        sqlKey: "timeOnPorkHouse",
        min: 1,
        max: 10,
        default: [1, 10],
    },

    {name: "planTypes.reproduction", isFullWidth: true},
    {
        name: "planTypes.inseminatedRenovationSows",
        sqlKey: "inseminatedGiltsAmount",
        min: 1,
        max: 200,
        default: [36, 40],
    },
    {
        name: "planTypes.inseminatedSows",
        sqlKey: "inseminatedSowsAmount",
        min: 1,
        max: 400,
        default: [60, 80],
    },
    {
        name: "planTypes.inseminatedAllSows",
        sqlKey: "inseminatedAmount",
        calc: true,
        formula: "{{inseminatedGiltsAmount}} + {{inseminatedSowsAmount}}",
        htmlFormula:
            "<mrow><mi>{{inseminatedGiltsAmount}}</mi><mo>+</mo><mi>{{inseminatedSowsAmount}}</mi></mrow>",
    }, // loszki + maciory
    {
        name: "planTypes.allUsgs",
        sqlKey: "usgAmount",
        min: 1,
        max: 100,
        default: [1, 100],
        custom: "{{amount}} / {{inseminated}} * 100",
        unit: ResultsGridUnitTypesV2.PERCENT,
    }, // cel w procentach, wartosc jako ilosc
    {
        name: "planTypes.giltsInseminationEffectiveness",
        sqlKey: "giltsInseminationEffectiveness",
        min: 0,
        max: 100,
        default: [95, 100],
    },
    {
        name: "planTypes.sowsInseminationEffectiveness",
        sqlKey: "sowsInseminationEffectiveness",
        min: 0,
        max: 100,
        default: [95, 100],
    },
    {
        name: "planTypes.inseminationEffectiveness",
        sqlKey: "inseminationEffectiveness",
        min: 0,
        max: 100,
        default: [95, 100],
    },
    {
        name: "planTypes.parturitionedSowsAmount",
        sqlKey: "farrowingSows",
        calc: true,
        component: "ResultsV2InseminationEffectivenessTarget",
    }, // jezeli jakakolwiek miala porod, a liczba to nie 0
    {
        name: "planTypes.repetitionsForRenovationSows",
        sqlKey: "repetitionGiltsAmount",
        min: 0,
        max: 100,
        default: [0, 5],
    },
    {
        name: "planTypes.repetitionsForSows",
        sqlKey: "repetitionSowsAmount",
        min: 0,
        max: 100,
        default: [0, 5],
    },
    {
        name: "planTypes.numberOfRepetitions",
        sqlKey: "repetitionAmount",
        calc: true,
        formula: "{{repetitionGiltsAmount}} + {{repetitionSowsAmount}}",
        htmlFormula:
            "<mrow><mi>{{repetitionGiltsAmount}}</mi><mo>+</mo><mi>{{repetitionSowsAmount}}</mi></mrow>",
    }, // loszki + maciory
    {
        name: "planTypes.avgBrakeFromInsToRep",
        sqlKey: "averageRepetitionTime",
        min: 1,
        max: 50,
        default: [1, 21],
    },
    {
        name: "planTypes.avgBrakeFromSepToIns",
        sqlKey: "averageSeparationToInseminationDays",
        min: 1,
        max: 30,
        default: [1, 4],
    },
    {
        name: "planTypes.retention",
        sqlKey: "retention",
        min: 0,
        max: 100,
        default: [90, 100],
    },

    {name: "planTypes.parturitions", isFullWidth: true},
    {
        name: "planTypes.expectedParturitions",
        sqlKey: "expectedFarrowing",
        calc: true,
        component: "ResultsV2ExpectedFarrowingTarget",
    }, // jezeli jakakolwiek ma opozniony porod i jest niewyproszona to na czerwono
    {
        name: "planTypes.parturitionedSows",
        sqlKey: "farrowedAmount",
        calc: true,
        formula: farrowedAmountFormula,
        round: "ceil",
        htmlFormula: `<mrow>${farrowedAmountHTMLFormula}</mrow>`,
    }, // skutecznosc wyproszen * ilosc pokrytych
    {
        name: "planTypes.parturitionDiffDays",
        sqlKey: "shiftFarrowDay",
        min: -5,
        max: 5,
        default: [-2, 2],
    },
    {
        name: "planTypes.giltsFarrowingEffectiveness",
        sqlKey: "giltsFarrowingEffectiveness",
        min: 1,
        max: 100,
        default: [93, 100],
    },
    {
        name: "planTypes.sowsFarrowingEffectiveness",
        sqlKey: "sowsFarrowingEffectiveness",
        min: 1,
        max: 100,
        default: [93, 100],
    },
    {
        name: "planTypes.parturitionEffectiveness",
        sqlKey: "farrowingEffectiveness",
        min: 1,
        max: 100,
        default: [93, 100],
    },
    {
        name: "planTypes.allBornPiglets",
        sqlKey: "bornPiglets",
        calc: true,
        formula: `${farrowedAmountFormula} * {{averageBornPigletsForSow}}`,
        round: "ceil",
        htmlFormula: `<mrow>${farrowedAmountHTMLFormula}<mo>⋅</mo><mi>{{averageBornPigletsForSow}}</mi></mrow>`,
    }, // z maciory
    {
        name: "planTypes.allBornPigletsPerSow",
        sqlKey: "averageBornPigletsForSow",
        min: 8,
        max: 40,
        default: [8, 18],
        step: 0.1,
    },
    {
        name: "planTypes.bornHealthyPiglets",
        sqlKey: "aliveBornPiglets",
        calc: true,
        formula: `${farrowedAmountFormula} * {{aliveBornPigletsForSow}}`,
        round: "ceil",
        htmlFormula: `<mrow>${farrowedAmountHTMLFormula}<mo>⋅</mo><mi>{{aliveBornPigletsForSow}}</mi></mrow>`,
    }, // z maciory
    {
        name: "planTypes.bornHealthyPigletsPerSow",
        sqlKey: "aliveBornPigletsForSow",
        min: 8,
        max: 40,
        default: [8, 18],
        step: 0.1,
    },
    {
        name: "planTypes.bornWeakPiglets",
        sqlKey: "weakBornPiglets",
        calc: true,
        formula: `${farrowedAmountFormula} * {{weakBornPigletsForSow}}`,
        round: "ceil",
        htmlFormula: `<mrow>${farrowedAmountHTMLFormula}<mo>⋅</mo><mi>{{weakBornPigletsForSow}}</mi></mrow>`,
    }, // z maciory
    {
        name: "planTypes.bornWeakPigletsPerSow",
        sqlKey: "weakBornPigletsForSow",
        min: 0,
        max: 40,
        default: [0, 1],
        step: 0.1,
    },
    {
        name: "planTypes.percentageOfWeakPigletsBornRelativeToHealthy",
        sqlKey: "weakToAlivePigletsRatio",
        min: 0,
        max: 100,
        default: [0, 2],
    },
    {
        name: "planTypes.bornMummyPiglets",
        sqlKey: "mummyBornPiglets",
        calc: true,
        formula: `${farrowedAmountFormula} * {{mummyBornPigletsForSow}}`,
        round: "ceil",
        htmlFormula: `<mrow>${farrowedAmountHTMLFormula}<mo>⋅</mo><mi>{{mummyBornPigletsForSow}}</mi></mrow>`,
    }, // z maciory
    {
        name: "planTypes.bornMummyPigletsPerSow",
        sqlKey: "mummyBornPigletsForSow",
        min: 0,
        max: 40,
        default: [0, 1],
        step: 0.1,
    },
    {
        name: "planTypes.bornDeadPiglets",
        sqlKey: "deadBornPiglets",
        calc: true,
        formula: `${farrowedAmountFormula} * {{deadBornPigletsForSow}}`,
        round: "ceil",
        htmlFormula: `<mrow>${farrowedAmountHTMLFormula}<mo>⋅</mo><mi>{{deadBornPigletsForSow}}</mi></mrow>`,
    }, // z maciory
    {
        name: "planTypes.bornDeadPigletsPerSow",
        sqlKey: "deadBornPigletsForSow",
        min: 0,
        max: 40,
        default: [0, 1],
        step: 0.1,
    },
    {
        name: "planTypes.averageBornPigletsWeight",
        sqlKey: "averagePigletWeight",
        min: 100,
        max: 10000,
        default: [1000, 2000],
        step: 0.1,
    },
    {
        name: "numberOfPigletsBySow",
        sqlKey: "numberOfPigletBySow",
        min: 0,
        max: 1000,
        default: [0, 200],
    },
    {
        name: "controlLists.mainView.fosteringTitle",
        sqlKey: "fostering",
        min: 0,
        max: 100,
        default: [0, 100],
    },

    {name: "separation", isFullWidth: true},
    {
        name: "planTypes.separatedSows",
        sqlKey: "separatedLitters",
        calc: true,
        formula: farrowedAmountFormula,
        round: "ceil",
    }, // skutecznosc wyproszen
    {
        name: "numberOfSeparatedPerSeparation",
        sqlKey: "separatedPiglets",
        calc: true,
        formula: `${farrowedAmountFormula} * {{separatedPigletForSow}}`,
        round: "ceil",
        htmlFormula: `<mrow>${farrowedAmountHTMLFormula}<mo>⋅</mo><mi>{{separatedPigletForSow}}</mi></mrow>`,
    }, // z maciory
    {
        name: "numberOfSeparatedPerFarrowing",
        sqlKey: "weanedPerFarrowing",
        min: 2,
        max: 20,
        default: [12, 16],
        step: 0.1,
    },
    {
        name: "planTypes.amountOfSeparatedPigletsPerSow",
        sqlKey: "separatedPigletForSow",
        min: 5,
        max: 20,
        default: [12, 16],
        step: 0.1,
    },
    // { name: "planTypes.weightOfPitcherWeight", sqlKey: "separatedLitterWeight", calc: true, formula: "{{averageSeparatedPigletWeight}} * {{separatedPigletForSow}}", htmlFormula: "<mrow><mi>{{averageSeparatedPigletWeight}}</mi><mo>⋅</mo><mi>{{separatedPigletForSow}}</mi></mrow>" }, // srednia waga odsadzonego prosiaka
    {
        name: "planTypes.litterWeight",
        sqlKey: "separatedWeight",
        calc: true,
        formula: `${farrowedAmountFormula} * {{separatedPigletForSow}} * {{averageSeparatedPigletWeight}}`,
        round: "ceil",
        htmlFormula: `<mrow>${farrowedAmountHTMLFormula}<mo>⋅</mo><mi>{{separatedPigletForSow}}</mi><mo>⋅</mo><mi>{{averageSeparatedPigletWeight}}</mi></mrow>`,
    }, // srednia waga odsadzonego prosiaka
    {
        name: "planTypes.averageWeightOfSeparatedPiglet",
        sqlKey: "averageSeparatedPigletWeight",
        min: 400,
        max: 12000,
        default: [5000, 7000],
        step: 10,
    },
    {
        name: "planTypes.pigletsMortality",
        sqlKey: "pigletsMortality",
        min: 0,
        max: 30,
        default: [0, 10],
    },
    {
        name: "planTypes.numberOfFallenPigs",
        sqlKey: "deadPiglets",
        min: 0,
        max: 30,
        default: [0, 100],
    }, // smiertelnosc prosiat
    {
        name: "planTypes.numberOfFallenPigsForSow",
        sqlKey: "deadPigletsForSow",
        min: 0,
        max: 30,
        default: [0, 10],
    }, // smiertelnosc prosiat
    {
        name: "sowResults.lactationDays",
        sqlKey: "lactationDays",
        min: 0,
        max: 40,
        default: [24, 30],
    },
    {
        name: "separatedToBabyRoom",
        sqlKey: "separationsToBabyRoom",
        min: 0,
        max: 200,
        default: [0, 30],
    },
    {
        name: "modals.editSowCycle.separationToPiglethouse",
        sqlKey: "separationsToPigletHouse",
        min: 0,
        max: 3000,
        default: [0, 30],
    },
    {
        name: "culledSows",
        sqlKey: "culledSows",
        min: 0,
        max: 50,
        default: [0, 3],
    },
    {name: "SType.4", isFullWidth: true},
    {
        name: "separatedFromBabyRoom",
        sqlKey: "separationsFromBabyRoom",
        min: 0,
        max: 200,
        default: [0, 30],
    },
    {
        name: "babyRoomFall",
        sqlKey: "babyRoomFall",
        min: 0,
        max: 200,
        default: [0, 30],
    },
];
