import React from "react";
import PropTypes from "prop-types";
import Input from "../Input";
import {myID} from "../../../../libs/generateID";
import Tooltip from "../../tooltip/Tooltip";

export default class LabeledInput extends React.PureComponent {

    onChange = (value) => {
        this.props.onChange(value);
    };

    onBlur = e => {
        this.props.onBlur(e);
    };

    render() {
        const {
            id, label, type, disabled, value, error, unit, required, optionalProps, className, frName, name,
            onChangeWithEvent, min, autofocus, placeholder, warning, max, showIconOnErrorOnWarning, onFocus,
            autocomplete, infoBoxContent, dataList, showLabel = true, readOnly = false
        } = this.props;
        return (
            <>
                {showLabel && (
                    <label htmlFor={id}>
                        {label}
                        {required && <sup>*</sup>}
                        {infoBoxContent !== "" && (
                            <Tooltip
                                tooltipContent={infoBoxContent}
                                type={"secondary"}>
                                <i className="fas fa-info-circle ms-1 fa-fw"/>
                            </Tooltip>
                        )}
                    </label>
                )}

                <Input
                    readOnly={readOnly}
                    dataList={dataList}
                    className={className}
                    disabled={disabled}
                    type={type}
                    id={id}
                    onChange={(value) => this.onChange(value)}
                    value={value}
                    frName={frName}
                    min={min}
                    error={error}
                    unit={unit}
                    optionalProps={optionalProps}
                    onBlur={this.onBlur}
                    name={name}
                    onChangeWithEvent={onChangeWithEvent}
                    autofocus={autofocus}
                    placeholder={placeholder}
                    warning={warning}
                    max={max}
                    showIconOnErrorOnWarning={showIconOnErrorOnWarning}
                    onFocus={onFocus}
                    autocomplete={autocomplete}
                />
            </>
        );
    }

}

LabeledInput.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf(["date", "datetime-local", "email", "file", "hidden", "image", "month", "number", "password", "range", "reset", "search", "submit", "tel", "text", "time", "url", "week"]).isRequired,
    id: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    error: PropTypes.string,
    unit: PropTypes.string,
    className: PropTypes.string,
    required: PropTypes.bool,
    frName: PropTypes.string,
    name: PropTypes.string,
    onChangeWithEvent: PropTypes.bool,
    onFocus: PropTypes.func,
    infoBoxContent: PropTypes.string,
};

LabeledInput.defaultProps = {
    id: myID(),
    required: false,
    onChange: (text) => {
    },
    onBlur: (text) => {
    },
    optionalProps: {},
    infoBoxContent: ''
};
