import {first} from "lodash";
import moment from "moment";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import useQueryCaller from "../../../hooks/useQueryCaller";
import {getSelectedAnimalForDocuments} from "../../../selectors/animalDocumentsSelectors";
import {getAnimalParametersFirstFetching} from "../../../selectors/animalParametersSelector";
import {formatLocationName} from "../../../utils/global-formatters/formatLocationName";
import {getGroupMortalityChartData} from "../../../utils/GroupUtils";
import LoadingComponent from "../../loading/LoadingComponent";
import CollapsableContainer from "../containers/CollapsableContainer";
import ChartTemplate from "../ideal-charts/charts/ChartTemplate";
import useAnimalGroupInfo from "../ideal-charts/useAnimalGroupInfo";

function CustomTooltip({label, payload}) {
    const {t} = useTranslation();

    const locations = first(payload)?.payload?.locations;
    const formattedLocations = formatLocationName(locations, {maxItems: 10});
    console.log(formattedLocations, "testtt");
    return (
        <div className="compare-insertion-tooltip recharts-default-tooltip">
            <div>{moment(label).format("DD.MM.YY")}</div>
            <ul>
                {!!payload &&
                    payload.map((item, i) => (
                        <li key={i} style={{color: item.fill}}>
                            <span>
                                {item.name}:{" "}
                                {item.formatter
                                    ? item.formatter(item.value)
                                    : item.value}
                                {item.unit}
                            </span>
                        </li>
                    ))}
            </ul>
            {locations?.length > 0 && (
                <div className="text-wrap mw-50">
                    {t("location")}: {formattedLocations}
                </div>
            )}
        </div>
    );
}

function GroupMortalityRenderer() {
    const {t} = useTranslation();

    const [mortalityData, setMortalityData] = useState([]);
    const [loading, setLoading] = useState(false);

    const ref = useRef(null);

    const queryCaller = useQueryCaller();
    const group = useSelector(getSelectedAnimalForDocuments);

    const groupInfo = useAnimalGroupInfo();

    const getLocationsForTime = useCallback(
        (time) => {
            const locations = Object.keys(groupInfo.animalNumberPerLocation);
            const tmp = [];
            for (const locationId of locations) {
                const animals = groupInfo.getNumberByTimeAndLocation(
                    +time,
                    locationId
                );
                if (animals) {
                    tmp.push(locationId);
                }
            }
            return tmp;
        },
        [groupInfo]
    );

    useEffect(() => {
        async function fetchData() {
            try {
                setLoading(true);
                const result = await getGroupMortalityChartData(
                    queryCaller,
                    group.AnmGrp,
                    group.DtaDltTime
                );
                setMortalityData(result);
            } catch (e) {
                console.error(e);
            } finally {
                setLoading(false);
            }
        }

        fetchData();
    }, [group.AnmGrp, group.DtaDltTime, queryCaller]);

    const data = useMemo(() => {
        const data = (mortalityData || []).map((item) => {
            const locations = getLocationsForTime(item.time);
            return {...item, locations};
        });

        return data;
    }, [mortalityData, getLocationsForTime]); // eslint-disable-line react-hooks/exhaustive-deps

    const percentFormatter = useCallback((value) => {
        if (!isFinite(value)) return;
        return value.toFixed(2);
    }, []);

    const dataDef = useMemo(() => {
        return [
            {
                dataKey: "insertedAnimals",
                color: "success",
                name: t("animalCount"),
                yAxisId: "right",
                opacity: 0.2,
            },
            {
                dataKey: "mortality",
                color: "error",
                name: t("mortality"),
                yAxisId: "left",
                unit: "%",
                formatter: percentFormatter,
            },
            {
                dataKey: "weanerMortality",
                color: "warning",
                name: t("planTypes.weanerMortality"),
                yAxisId: "left",
                unit: "%",
                formatter: percentFormatter,
            },
            {
                dataKey: "finisherMortality",
                color: "info",
                name: t("planTypes.porkerMortality"),
                yAxisId: "left",
                unit: "%",
                formatter: percentFormatter,
            },
            {
                dataKey: "giltMortality",
                color: "purple",
                name: t("giltMortality"),
                yAxisId: "left",
                unit: "%",
                formatter: percentFormatter,
            },
        ];
    }, [percentFormatter, t]);

    // const dateFormatter = useCallback((value) => {
    //     return moment(value).format("L")
    // }, []);

    return (
        <div className="position-relative">
            <LoadingComponent isLoading={loading} />
            <ChartTemplate
                showOverflow
                overrideDesktop={true}
                show={true}
                domain={[0, "dataMax+5"]}
                data={data}
                error={false}
                YaxisName={t("mortality")}
                rightYaxisName={t("animalCount")}
                name={t("mortality")}
                loading={false}
                dataDef={dataDef}
                customTooltip={CustomTooltip}
                dataSet={data}
                defaultExpanded={true}
                saveAsExcell={t("mortality")}
                forwardRef={ref}
            />
        </div>
    );
}

export default function GroupMortality({loading}) {
    const {t} = useTranslation();

    const firstFetchingParams = useSelector(getAnimalParametersFirstFetching);

    if (firstFetchingParams || loading)
        return (
            <CollapsableContainer.Card header={t("mortality")} defaultExpanded>
                <div className="h-0 mh-15rem position-relative">
                    <LoadingComponent isLoading />
                </div>
            </CollapsableContainer.Card>
        );
    return <GroupMortalityRenderer />;
}
