import { initializeApp } from "firebase/app";
import { deleteToken, getMessaging, getToken } from "firebase/messaging";
import { registerSNSEndpoint } from "../actions/snsActions";
import { firebaseConfig } from "./FirebaseRegistrationUtils";

let messaging;

export const initializeFirebase = () => {
    if (navigator.serviceWorker) {
        initializeApp(firebaseConfig);
        messaging = getMessaging();
        console.log("messaging1", messaging);
    }
};

// TODO IOS Safari notifications
// https://developer.apple.com/library/archive/documentation/NetworkingInternet/Conceptual/NotificationProgrammingGuideForWebsites/PushNotifications/PushNotifications.html#//apple_ref/doc/uid/TP40013225-CH3-SW1


async function registerPeriodicSync() {
    if ("serviceWorker" in navigator) {
        const status = await navigator.permissions.query({
            name: "periodic-background-sync"
        });
        if (status.state === "granted") {
            const registration = await navigator.serviceWorker.ready;
            try {
                await registration.periodicSync.register("refresh-sns", {
                    minInterval: 1000 * 60 * 60 * 23 // odswiezaj token co 23h
                });
            } catch (e) {
                console.error("Error while registering periodic sync", e);
            }
        } else {
            console.log("Periodic sync permissions not granted, skipping");
        }
    }
}

function waitForServiceWorker() {
    return new Promise(async (resolve, reject) => {
        let timeout = setTimeout(() => {
            reject(new Error('Wait time exceeded 2000ms'));
        }, 2000);
        let registration = await navigator.serviceWorker.ready;
        clearTimeout(timeout);
        resolve(registration);
    })
}

export const askForPermisstionToReceiveNotifications = async () => {
    try {
        console.log("BABEL_ENV", process.env.BABEL_ENV)
        console.log("REACT_APP_STAGE", process.env.REACT_APP_STAGE)
        if (navigator.serviceWorker) {
            await Notification.requestPermission();
        }
        return null;
    } catch (e) {
        console.error(e);
        return null;
    }
};

export const registerSNS = async (user) => {
    const status = Notification.permission
    if (status === "granted" && process.env.REACT_APP_STAGE !== "local") {
        try {
            const registration = await waitForServiceWorker()
            console.log(`serviceWorkerRegistration ${registration}`)
            const token = await getToken(messaging, {
                serviceWorkerRegistration: registration
            });
            registerSNSEndpoint(token, user);
            await registerPeriodicSync();
            return token;
        }
        catch (e) {
            console.error(`Error registering SNS ${e}`)
        }
    }
}

export async function clearToken() {
    await deleteToken(messaging);
}
