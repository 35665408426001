import moment from "moment";
import {convertLengthUnitTo, convertTemperatureUnitTo} from "./UnitUtils";
import {UnitTypes} from "../constans/unitTypes";
import {findIndex, get, isArray, isNil, isNumber, isString, isObject, maxBy, memoize, uniqBy} from "lodash";
import {getMinutesOfDay, utcMomentToLocal} from "./DateTimeUtils";
import {getPassageAccuracy} from "./SettingsUtils";
import {calculateStandardDeviation, isBetween, isFiniteNumber} from "./MathUtils";
import {checkIfCageArrayContainsHalfWeights} from "./DevicesUtils";
import DevTypes from "@wesstron/utils/Api/constants/devTypes";

function getLastHour(timestamp) {
    let hours = moment(timestamp).hours();
    let minutes = moment(timestamp).minutes();
    if (minutes > 0) hours += 1;
    return Math.min(23, hours);
}

function getData(data, start, stop) {
    if (!stop) {
        return data.filter(item => item.AggTi >= start).sort((a, b) => +a.AggTi - +b.AggTi);
    }
    return data.filter(item => +item.AggTi >= start && +item.AggTi <= stop).sort((a, b) => +a.AggTi - +b.AggTi);
}

export function calculateDataForSensorsChart(currentDay) {
    let result = [], lastTime = 0;
    for (let row of currentDay.AggDt) {
        if (checkNonNullKeys(row, ["CO2"]) || checkNonNullKeys(row, ["NH3"])) {
            result.push({
                name: row.T,
                co2: row.CO2 ?? null,
                co2alert: row.MCO2 ?? null,
                nh3: row.NH3 ?? null,
                nh3alert: row.MNH3 ?? null
            });
            lastTime = getLastHour(row.T);
        }
    }
    return {
        result, lastTime
    };
}

export function calculateDataForFlapsAndThrottleChart(currentDay) {
    let result = [], lastTime = 0;
    for (let row of currentDay.AggDt) {
        if (checkNonNullKeys(row, [["TVol", "FVol", "FVolR", "TVolR"]])) {
            result.push({
                time: row.T,
                name: moment(row.T).format("HH:mm"),
                throttleVoltage: row.TVol ?? null,
                flapsVoltage: row.FVol ?? null,
                flapsVoltageRead: row.FVolR ?? null,
                throttleVoltageRead: row.TVolR ?? null,
            });
            lastTime = getLastHour(row.T);
        }
    }
    return {
        result, lastTime
    };
}

export function calculateDataForCo2Chart(currentDay) {
    let co2 = [];
    let lastTime = 0;
    for (let row of currentDay.AggDt) {
        if (checkNonNullKeys(row, ["CO2"])) {
            co2.push({
                name: row.T,
                co2: row.CO2,
                alert: row.MCO2,
            });
            lastTime = getLastHour(row.T);
        }
    }
    return {
        co2, lastTime
    };
}

export function calculateDataForAiringChart(currentDay) {
    let airing = [];
    let lastTime = 0;
    for (let row of currentDay.AggDt) {
        if (checkNonNullKeys(row, ["VenS", "VWoT", "VBrT", "VRPM"])) {
            airing.push({
                name: row.T,
                status: +!!row.VenS,
                workTime: +!!row.VenS ? row.VWoT : null,
                breakTime: +!!row.VenS ? row.VBrT : null,
                rpm: +!!row.VenS ? row.VRPM : null
            });
            lastTime = getLastHour(row.T);
        }
    }
    return {
        airing, lastTime
    };
}

export function calculateDataForH2sChart(currentDay) {
    let h2s = [];
    let lastTime = getLastHour(currentDay.AggDt[currentDay.AggDt.length - 1].T);
    for (let row of currentDay.AggDt) {
        if (row.H2S !== undefined) {
            h2s.push({
                name: row.T,
                h2s: row.H2S
            });
        }
    }
    return {
        h2s, lastTime
    };
}

const _defaultDatasetCheck = ({dataKey = ""} = {}) => +dataKey.endsWith("Shadow");
const _getLabel = (label, secondaryLabel) => {
    if (!secondaryLabel) return label;
    return `${label} (${secondaryLabel})`;
}
export const appendHeaderLabels = (chartDef, labelDataset1, labelDataset2, dataSetCheck = _defaultDatasetCheck) => {
    return chartDef.map((obj) => {
        const newName = dataSetCheck(obj) ? _getLabel(obj.name, labelDataset2) : _getLabel(obj.name, labelDataset1)
        return {
            ...obj,
            name: newName
        }
    })
}

export function getShadowDataChartDef(chartDef, applyShadowData) {
    if (!applyShadowData) return chartDef;
    const result = chartDef;
    chartDef.forEach(def => {
        if (def.dataKey !== "name") {
            const tmp = {...def};
            tmp.dataKey += "Shadow"
            if (tmp.stack) {
                tmp.stack += "Shadow"
            }
            tmp.opacity = 0;
            if (isObject(tmp._shadow)) {
                Object.assign(tmp, tmp._shadow);
                delete tmp._shadow;
            }
            result.push(tmp)
        }
    })
    return chartDef;
}

export function mergeDataForCageWeight(data, shadowData, offset) {
    return convertToShadowData(data, shadowData, "weights", offset);
}

export function mergeDataForPassageInDays(data, shadowData, offset) {
    return convertToShadowData(data, shadowData, "passagesInDays", offset);
}

export function mergeDataForCageGain(data, shadowData, offset) {
    return convertToShadowData(data, shadowData, "gains", offset);
}

export function mergeDataForPassageInWeight(data, shadowData) {
    return convertToShadowData(data, shadowData, "passageInWeights");
}

function convertToShadowData(data, shadowData, chartType = "weights", offset = 0) {
    const results = [];
    switch (chartType) {
        case "gains":
        case "passagesInDays":
        case "weights": {
            const length = data.length;
            for (let i = 0; i < length; i++) {
                const keys = Object.keys(data[i]);
                results[i] = {
                    ...data[i]
                }
                try {
                    keys.forEach(key => {
                        results[i][`${key}Shadow`] = shadowData[i + offset][key];
                    })
                } catch (err) {
                    console.log(err);
                    keys.forEach(key => {
                        results[i][`${key}Shadow`] = null;
                    })
                }
            }
            break;
        }
        case "passageInWeights": {
            if (data.length === shadowData.length) {
                for (let i = 0; i < data.length; i++) {
                    results[i] = {
                        ...data[i],
                        amountShadow: shadowData[i].amount
                    }
                }
            } else {
                let iterator = 0;
                const big = data.length > shadowData.length ? data : shadowData;
                const small = data.length > shadowData.length ? shadowData : data;
                for (let i = 0; i < small.length; i++) {
                    results[i] = {
                        ...small[i],
                        amountShadow: (big[iterator]?.amount || 0) + (big[iterator + 1]?.amount || 0)
                    }
                    iterator += 2;
                }
            }
            break;
        }
        default:
            break;
    }
    return results;

}

export function calculateDataForCageHourlyChart(aggData, date, standardDeviationDays = 2, animalAmount = 300) {
    //o dziwo pokrywa sie stare z nowym
    return calculateDataForHourlyChart(aggData, date, standardDeviationDays, animalAmount);
}

export function calculateDataForHourlyChart(cageData, date, standardDeviationDays = 2, animalAmount = 300) {
    let data = cageData.find(item => +item.AggTi === date);
    if (!data) return [];
    let hourly = [];
    const standardDeviationData = cageData.filter(item => item.AggTi < date).sort((a, b) => b.AggTi - a.AggTi).slice(0, standardDeviationDays);
    console.log(standardDeviationData);
    const populationPercent = Math.round(0.04 * animalAmount);
    for (let i = 0; i < 24; i++) {
        const {
            deviation: standardDeviationAll,
            average
        } = calculateStandardDeviation(standardDeviationData.map(item => item.AggDt[0].VisAll[i]));
        const all = get(data, `AggDt[0].VisAll[${i}]`, 0);
        const left = get(data, `AggDt[0].VisLeft[${i}]`, 0);
        const right = get(data, `AggDt[0].VisRight[${i}]`, 0);
        const middle = get(data, `AggDt[0].VisMid[${i}]`, 0);
        const expectedTraffic = [+Math.round(average - (standardDeviationAll + populationPercent)).toFixed(2), +Math.round(average + standardDeviationAll + populationPercent).toFixed(2)];
        const isError = all < expectedTraffic[0] || all > expectedTraffic[1];
        hourly.push({
            name: `${i}:00`.padStart(5, "0"),
            left,
            right,
            all,
            middle,
            expectedTraffic,
            error: isError ? all : null
        })
    }
    return hourly;
}

export function calculateDataForHumidityChart(currentDay) {
    let humidity = [];
    let lastTime = 0;
    for (let row of currentDay.AggDt) {
        if (checkNonNullKeys(row, ["Temp", "Humid"])) {
            humidity.push({
                name: row.T,
                humidity: row.Humid,
                temperature: row.Temp
            });
            lastTime = getLastHour(row.T);

        }
    }
    return {
        humidity, lastTime
    };
}

export function calculateDataForNh3Chart(currentDay) {
    let nh3 = [];
    const lastTime = getLastHour(currentDay.AggDt[currentDay.AggDt.length - 1].T);
    for (let row of currentDay.AggDt) {
        if (row.NH3 !== undefined) {
            nh3.push({
                name: row.T,
                nh3: row.NH3
            });
        }
    }
    return {
        nh3, lastTime
    };
}

export function calculateDataForPressureChart(currentDay) {
    let pressure = [];
    let lastTime = 0;
    for (let row of currentDay.AggDt) {
        if (checkNonNullKeys(row, ["CoolPress"])) {
            pressure.push({
                name: row.T,
                pressure: row.CoolPress
            });
            lastTime = getLastHour(row.T);
        }
    }
    return {
        pressure, lastTime
    };
}

function _calculateDataForGenericSiloChart(scaleData, date, TimeKey, WeightKey, VolumeKey) {
    let currentDay = scaleData.filter(item => item.AggTi === date)[0];
    let data = [];
    if (currentDay) {
        for (let row of currentDay.AggDt) {
            if (isFiniteNumber(row[WeightKey]) && ![0x7FFFFFFF, 0x7FFFFFFF * 1000].includes(row[WeightKey])) {
                data.push({
                    name: moment(row[TimeKey]).format("HH:mm"),
                    weight: row[WeightKey],
                    volume: VolumeKey ? row[VolumeKey] ?? null : null,
                })
            }
        }
    }
    return data;
}

export function calculateDataForScaleChart(scaleData, date) {
    return _calculateDataForGenericSiloChart(scaleData, date, "T", "W");
}

export function calculateDataForSiloRadarChart(scaleData, date) {
    return _calculateDataForGenericSiloChart(scaleData, date, "T", "W", "V");
}

export function calculateDataForSiloSensorChart(scaleData, date) {
    return _calculateDataForGenericSiloChart(scaleData, date, "TS", "W");
}

export function calculateDataForElectricalDailyChart(aggData, start, end) {
    // na te chwile jest to samo wiec yolo
    return calculateDataForWaterDailyChart(...arguments);
}


function _calculateCageChart(prefix = "", aggData, start, end) {
    const data = getData(aggData, start, end);
    console.log(data);
    const result = [];
    for (let item of data) {
        const dateStr = moment.utc(item.AggTi);
        try {
            if (!result.find(data => ((moment(data.name).format("MMDDYYYY")) === moment(dateStr).format("MMDDYYYY")))) {
                result.push({
                    "name": +dateStr,
                    "all": item.AggDt[0][`${prefix}All`] || null,
                    "left": item.AggDt[0][`${prefix}Left`] || null,
                    "right": item.AggDt[0][`${prefix}Right`] || null,
                    "middle": item.AggDt[0][`${prefix}Mid`] || null,
                });
            }
        } catch (e) {
            console.error(e);
            result.push({
                "name": +dateStr,
                "all": null,
                "left": null,
                "right": null,
                "middle": null,
            })
        }
    }
    return result;
}

export function calculateDataForPigletScalePassesChart(aggData, date, {chartType = "GPNA"} = {}) {
    const data = aggData.find(a => a.AggTi === date);
    const accuracy = getPassageAccuracy();
    const divider = accuracy === 0.5 ? 500 : 1000;
    try {
        if (data) {
            const tmp = {};
            const result = [];
            data.AggDt[data.AggDt.length - 1][chartType].forEach(([localTimeString, weightRaw]) => {
                const index = Math.floor(weightRaw / divider);
                console.log(weightRaw, index)
                tmp[index] = tmp[index] ? tmp[index] + 1 : 1;
                console.log(tmp)
            })
            console.log(tmp)
            const maxIndex = Math.max(...Object.keys(tmp).map((number) => +number), 361);
            console.log(maxIndex)
            for (let i = 0; i < maxIndex; i++) {
                result[i] = {
                    name: (i * divider / 1000),
                    amount: tmp[i] || 0
                }
            }
            return result;
        }
    } catch (err) {
        //err
        console.log(err, "=(")
    }
    return [];
}

export function calculateDataForPigletScaleHourlyChart(aggData, date, {chartType = "GPNA"} = {}) {
    const data = aggData.find(a => a.AggTi === date);
    try {
        if (data) {
            const result = [];
            for (let i = 0; i < 24; i++) {
                result.push({
                    name: `${i}:00`.padStart(5, "0"),
                    all: 0
                })
            }
            data.AggDt[data.AggDt.length - 1][chartType].forEach(([localTimeString]) => {
                const index = moment(localTimeString, "YYYY-MM-DD HH:mm:ss").hours();
                result[index].all += 1;
            })
            return result;
        }
    } catch (err) {
        //err
        console.log(err, "=(")
    }
    return [];
}

export function calculateDataForCageWeightChart(aggData, start, end) {
    return _calculateCageChart("WeightEx", aggData, start, end);
}

export function calculateDataForCagePassageInDaysChartChart(aggData, start, end) {
    const result = _calculateCageChart("Vis", aggData, start, end);
    const names = ["all", "left", "right", "middle"];
    for (let i = 0; i < result.length; i++) {
        names.forEach(key => {
            if (isArray(result[i][key])) {
                result[i][key] = result[i][key].reduce((a, b) => a + b, 0);
            } else {
                result[i][key] = 0;
            }
        })
    }
    const firstIndexWithData = findIndex(result, o => o.all > 0);
    return result.slice(firstIndexWithData);
}

export function calculateDataForCageGainChart(aggData, start, end) {
    const result = [];
    const data = getData(aggData, start, end);
    if (data.length) {
        let prevWeight = null;
        let prevDay = null;
        for (let item of data) {
            const currentDay = moment.utc(item.AggTi);
            const currentWeight = item.AggDt[0]?.WeightExAll ?? 0;
            const tmp = {
                name: +currentDay,
                all: item.AggDt[0]?.Gain ?? null, // `Gain` is supported since 12.08.2024
            };
            if (tmp.all === null && prevDay && currentWeight && prevWeight) {
                // calculate the `Gain` manually to support older AggDt
                const dayDifference = moment(currentDay).startOf("day").diff(moment(prevDay).startOf("day"), "days");
                tmp.all = (currentWeight - prevWeight) / dayDifference;
            }
            prevWeight = currentWeight;
            prevDay = currentDay;
            result.push(tmp);
        }
        return uniqBy(result, ({name}) => name);
    }
}

export function calculateDataForCagePassageChart(aggData, date, isIpsum) {
    const accuracy = getPassageAccuracy();
    const data = aggData.find(item => +item.AggTi === date);
    let counter = isIpsum ? 0 : 10;
    let passage = [];
    if (!data) return passage;
    const hasHalfWeights = isIpsum ? true : checkIfCageArrayContainsHalfWeights(data.AggDt[0].VisWeight)
    if (accuracy === 0.5) {
        passage = data.AggDt[0].VisWeight.map(item => {
            let tmp = {"name": counter, "amount": item};
            counter += hasHalfWeights ? 0.5 : 1;
            return tmp;
        });
    } else if (accuracy === 1) {
        for (let i = 0; i < data.AggDt[0].VisWeight.length; hasHalfWeights ? i += 2 : i++) {
            if (hasHalfWeights) {
                let item1 = data.AggDt[0].VisWeight[i];
                let item2 = data.AggDt[0].VisWeight[i + 1];
                passage.push({
                    name: counter,
                    amount: item1 + item2
                });
                counter++;
            } else {
                counter++;
                let item = data.AggDt[0].VisWeight[i];
                passage.push({
                    name: i,
                    amount: item
                })
            }
        }
    }
    return passage;
}

export function calculateDataForCageRFIDChart(rfid, aggData, start, end) {
    const data = getData(aggData, start, end);
    data.sort((a, b) => a.AggTi - b.AggTi);
    let passes = [];
    for (let d of data) {
        let passesForRFID = d.AggDt[0].RFIDPass.find(item => item.RFID === rfid);
        if (passesForRFID) {
            for (let pass of passesForRFID.P) {
                if (pass.W > 0) {
                    passes.push({
                        name: moment.utc(d.AggTi).format("DD.MM.YY") + " " + moment(pass.T).format(" HH:mm"),
                        weight: pass.W
                    })
                }
            }
        }
    }
    return passes;
}

export function calculateDataForWaterDailyChart(aggData, start, end) {
    const getUsage = (AggDt = []) => {
        if (AggDt.length > 1) {
            return AggDt[AggDt.length - 1].MR - AggDt[0].MR
        }
        return null;
    };
    const getMaxFlow = (AggDt = []) => {
        if (AggDt.length) {
            return AggDt[AggDt.length - 1].MF || null;
        }
        return null;
    }
    const getMaxFlowAlert = (AggDt = []) => {
        return maxBy(AggDt, (o) => o.FVA)?.FVA ?? null
    }
    const result = [];
    const startDate = moment.utc(+start).startOf("day");
    const diff = Math.abs((moment.utc(+start)).diff(moment.utc(+end), "days")) + 1;
    for (let i = 0; i < diff; i++) {
        const aggDt = get(aggData.find(d => d.AggTi === +startDate.clone().add(i, "days")), "AggDt");
        result[i] = {
            name: startDate.clone().add(i, "days").format("DD.MM"),
            consumption: getUsage(aggDt),
            maxFlow: getMaxFlow(aggDt),
            maxFlowAlert: getMaxFlowAlert(aggDt)
        }
    }
    return result;
}

const getValuesInTimespan = (dayData = [], minutesMin, minutesMax, keys, {_startIndex = 0} = {}) => {
    const result = {
        hasData: {},
        value: {},
        _startIndex: 0
    };
    for (let i = _startIndex; i < dayData.length; i++) {
        const minutes = getMinutesOfDay(dayData[i].T);
        if (!isNil(dayData[i].T) && (minutes >= minutesMin) && (minutes < minutesMax)) {
            result._startIndex = i;
            for (let key of keys) {
                result.value[key] = dayData[i][key] ?? null;
                result.hasData[key] = !isNil(dayData[i][key]);
            }
        }
    }
    return result;
}

const getValueByTick = (dayData = [], key, tickNumber, tickMinutes = 30, _startIndex = 0, _defaultValue = 0) => {
    const minutesStart = tickNumber * tickMinutes;
    const minutesStop = (tickNumber + 1) * tickMinutes;
    const r = getValuesInTimespan(dayData, minutesStart, minutesStop, key, {_startIndex});
    if (isArray(key)) {
        for (let k in r.value) {
            if (!r.value.hasOwnProperty(k)) continue;
            r.value[k] ??= _defaultValue;
        }
    } else {
        r.value = r.value[key];
        r.hasData = r.hasData[key];
        r.value ??= _defaultValue;
    }
    return r;
}

export const getTickUsage = (dayData = [], tickNumber, tickMinutes = 30, _startIndex = 0) => {
    return getValueByTick(dayData, "MR", tickNumber, tickMinutes, _startIndex, 0);
};

export function calculateDataForElectricityHourlyChart(aggData, date) {
    const result = [];
    const start = moment(date).startOf("day");
    let startIndex;
    const day = aggData.find(d => d.AggTi === +date);
    if (day) {
        for (let i = 0; i < 48; i++) {
            const tmp = getTickUsage(day.AggDt, i, 30, startIndex);
            startIndex = tmp._startIndex;
            const before = get(result[i - 1], "meter", get(day.AggDt[0], "MR", 0));
            const current = tmp.hasData ? tmp.value : before;
            result[i] = {
                time: +start.clone().add(i * 30, "minute"),
                name: start.clone().add(i * 30, "minute").format("HH:mm"),
                meter: current,
                consumption: (isNil(current) || isNil(before)) ? null : current - before
            }
        }
    }
    return result;
}


export function calculateDataForWaterHourlyChart(aggData, date) {
    const result = [];
    const start = moment(date).startOf("day");
    let startIndex;
    const day = aggData.find(d => d.AggTi === +date);
    if (day) {
        for (let i = 0; i < 48; i++) {
            const {
                _startIndex,
                hasData,
                value
            } = getValueByTick(day.AggDt, ["MR", "AF", "MF", "FVA"], i, 30, startIndex, 0);
            startIndex = _startIndex;
            const before = get(result[i - 1], "meter", get(day.AggDt[0], "MR", 0));
            const current = hasData["MR"] ? value["MR"] : before;
            result[i] = {
                time: +start.clone().add(i * 30, "minute"),
                name: start.clone().add(i * 30, "minute").format("HH:mm"),
                meter: current,
                consumption: (isNil(current) || isNil(before)) ? null : current - before,
                averageFlow: hasData["AF"] ? value["AF"] : null,
                maxFlow: hasData["MF"] ? value["MF"] : null,
                flowAlert: hasData["FVA"] ? value["FVA"] || null : null,
            }
        }
    }
    return result;
}

export function calculateDataForIpsumChartDuration(aggData, start, end, forages) {
    console.log(aggData, forages);
    let durationData = aggData.filter(item => item.AggTi >= start && item.AggTi <= end).sort((o1, o2) => o1.AggTi - o2.AggTi);
    console.log(durationData);
    // const map = new Map();
    let forageData = {
        notFound: {data: {}, name: "notFound"}
    };
    for (const day of durationData) {
        // let consumptionInHour = map.get(hourKey) || 0;
        for (let row of day.AggDt) {

            if (isArray(row.Cons)) {
                for (let cons of row.Cons) {
                    const addForageData = (ForageKey, ConsKey, IsMandatory = true) => {
                        const forageId = cons[ForageKey];
                        const consumption = cons[ConsKey];
                        if (forageId) {
                            if (!forageData[forageId]) {
                                let forage = forages.find(item => item.SetID === forageId);
                                forageData[forageId] = {
                                    data: {},
                                    color: forage?.SetData.Color,
                                    name: forage?.SetData.Name
                                };
                            }
                            forageData[forageId].data[day.AggTi] = (forageData[forageId].data[day.AggTi] || 0) + consumption || 0;
                        } else {
                            if (IsMandatory === false && !isFiniteNumber(consumption)) {
                                return;
                            }
                            forageData.notFound.data[day.AggTi] = (forageData.notFound.data[day.AggTi] || 0) + consumption || 0;
                        }
                    }
                    addForageData("FID", "C", true);
                    addForageData("FID2", "C2", false);
                    // consumptionInHour += cons.C;
                }
            }
        }
        // map.set(hourKey, consumptionInHour);
    }
    if (Object.keys(forageData.notFound.data).length === 0) {
        delete forageData.notFound;
    }
    console.log(forageData);
    let data = {forages: [], data: {}};
    for (let SetID in forageData) {
        data.forages.push({SetID, color: forageData[SetID].color, name: forageData[SetID].name});
        for (let timestamp in forageData[SetID].data) {
            data.data[timestamp] = {
                keys: [],
                ...(data.data[timestamp] || {}),
                name: +timestamp,
                [SetID]: forageData[SetID].data[timestamp]
            }
            data.data[timestamp].keys.push(SetID);
        }
    }
    data.data = Object.values(data.data);
    data.data.sort((a, b) => a.name - b.name);
    console.log(data);
    return data;
    // return [...map.entries()].map(([name, consumption]) => ({name, consumption}))
}

function createForageDataForDay(name, color) {
    let data = {
        data: {},
        name,
        color
    }
    for (let i = 0; i < 24; i++) {
        data.data[`${i.toString().padStart(2, "0")}:00`] = 0;
    }
    return data;
}

export function calculateDataForIpsumChartDaily(aggData, date, forages) {
    let currentDay = aggData.find(item => item.AggTi === date);
    const findForageById = memoize((forageId) => forages.find(item => item.SetID === forageId));
    if (currentDay) {
        let forageData = {};
        for (let row of currentDay.AggDt) {
            if (isArray(row.Cons)) {
                for (let cons of row.Cons) {
                    const hourKey = `${moment(cons.T).format("HH")}:00`;
                    const addForageData = (ForageKey, ConsKey, IsMandatory = true) => {
                        const forageId = cons[ForageKey];
                        const consumption = cons[ConsKey];
                        if (forageId) {
                            if (!forageData[forageId]) {
                                const forage = findForageById(forageId);
                                forageData[forageId] = createForageDataForDay(forage?.SetData.Name, forage?.SetData.Color);
                            }
                            forageData[forageId].data[hourKey] += consumption || 0;
                        } else {
                            if (IsMandatory === false && !isFiniteNumber(consumption)) {
                                return;
                            }
                            if (!forageData.notFound) {
                                forageData.notFound = createForageDataForDay("notFound");
                            }
                            forageData.notFound.data[hourKey] += consumption || 0;
                        }
                    }
                    addForageData("FID", "C", true);
                    addForageData("FID2", "C2", false);
                }
            }
        }
        console.log(forageData);
        let data = {forages: [], data: {}};
        for (let SetID in forageData) {
            data.forages.push({SetID, color: forageData[SetID].color, name: forageData[SetID].name});
            for (let timestamp in forageData[SetID].data) {
                data.data[timestamp] = {
                    keys: [],
                    ...(data.data[timestamp] || {}),
                    name: timestamp,
                    [SetID]: forageData[SetID].data[timestamp]
                }
                data.data[timestamp].keys.push(SetID);
            }
        }
        data.data = Object.values(data.data);
        data.data.sort((a, b) => a.name - b.name);
        console.log(data);
        return data;
    }
    return {forages: [], data: []};
}

export function calculateDataForSiloRadarDurationChart(scaleData, start, end) {
    const model = [
        {name: "volume", key: "V", isMandatory: true}
    ];
    return _calculateDataWithRange(scaleData, start, end, model);
}

/**
 * util do liczenia ekstremów wartości odczytow dla danego dnia
 * @param aggData {array}
 * @param start {number}
 * @param end {number}
 * @param model {array} - tablica obiektów {key: {string} - klucz w aggData, name: {string} nazwa}
 * @private
 */
function _calculateDataWithRange(aggData, start, end, model) {
    const periodData = aggData.filter(({AggTi}) => {
        return isBetween(AggTi, start, end);
    }).sort((o1, o2) => {
        return o1.AggTi - o2.AggTi;
    });
    const result = [];
    const mandatoryKeys = model.map(({isMandatory, key: keyName}) => isMandatory ? keyName : null).filter(o => !!o);
    for (let day of periodData) {
        const tmp = {};
        let currentIndex = -1;
        for (let {name, key} of model) {
            tmp[`${name}Average`] = 0;
            tmp[`${name}Counter`] = 0;
            tmp[`${name}Max`] = 0;
            tmp[`${name}Min`] = null;
            tmp[`${name}Usage`] = 0;
            let valueBefore = null;
            for (let row of day.AggDt) {
                if (row[key] !== undefined) {
                    if (checkNonNullKeys(row, mandatoryKeys)) {
                        tmp[`${name}Max`] = row[key] > tmp[`${name}Max`] ? row[key] : tmp[`${name}Max`];
                        tmp[`${name}Min`] = isNil(tmp[`${name}Min`]) || row[key] < tmp[`${name}Min`] ? row[key] : tmp[`${name}Min`];
                        tmp[`${name}Average`] += row[key];
                        tmp[`${name}Counter`] += 1;
                        if (valueBefore !== null) {
                            const diff = valueBefore - row[key];
                            if (diff > 0) {
                                tmp[`${name}Usage`] += diff;
                            }
                        }
                        valueBefore = row[key];
                    }
                }
            }
            if (tmp[`${name}Counter`]) {
                if (currentIndex === -1) {
                    currentIndex = result.length;
                }
                result[currentIndex] = {
                    ...result[currentIndex],
                    name: moment.utc(day.AggTi).format("DD.MM"),
                    [name]: [Math.round(tmp[`${name}Min`]), Math.round(tmp[`${name}Max`])],
                    [`${name}Average`]: Math.round(tmp[`${name}Average`] / tmp[`${name}Counter`]),
                    [`${name}Usage`]: tmp[`${name}Usage`]
                }
            }
        }
    }
    return result;
}

export function calculateDataForScaleDurationChart(scaleData, start, end) {
    const model = [
        {name: "weight", key: "W", isMandatory: true}
    ];
    return _calculateDataWithRange(scaleData, start, end, model);
}

export function calculateDataForSlurryChart(currentDay) {
    let slurry = [];
    let lastTime = moment(currentDay.AggDt[currentDay.AggDt.length - 1].T).hour();
    for (let row of currentDay.AggDt) {
        if (row.Slur !== undefined) {
            slurry.push({
                name: row.T,
                slurry: row.Slur
            });
        }
    }
    return {
        slurry, lastTime
    };
}

/**
 *
 * @param obj
 * @param keys {array<string|array<string>>}
 *        key as string - mandatory key (must have the key)
 *        key as array<string> - optional key (must have one of keys)
 * @return {boolean}
 */
function checkNonNullKeys(obj, keys = []) {
    for (let key of keys) {
        if (isArray(key)) {
            if (key.every((k) => isNil(get(obj, k)))) {
                return false;
            }
        } else if (isNil(get(obj, key))) {
            return false;
        }
    }
    return true;
}

export function calculateDataForTemperatureChartNRF(climateData, date) {
    let temps = new Map();
    let lastTime = 0;
    let currentDay = climateData.filter(item => item.AggTi === date)[0];
    if (currentDay) {
        if (currentDay.AggDt[0]?.Temp?.length) {
            for (let row of currentDay.AggDt[0].Temp) {
                if (checkNonNullKeys(row, ["T", "Temp"])) {
                    const isTempAlert = !(isNil(row.MaxT) || isNil(row.MinT)) && !isBetween(row.Temp, row.MinT, row.MaxT);
                    temps.set(row.T, {
                        name: row.T,
                        temperature: row.Temp,
                        tempRange: isNil(row.MaxT) || isNil(row.MinT) ? null : [row.MinT, row.MaxT],
                        alert: isTempAlert
                    })
                    lastTime = getLastHour(row.T);
                }
            }
        }
    }
    return {
        temps,
        lastTime
    };
}


export function calculateDataForTemperatureChart(climateData, date) {
    let temps = new Map();
    let lastTime = 0;
    let currentDay = climateData.filter(item => item.AggTi === date)[0];
    if (currentDay) {
        if (currentDay.DeTy === DevTypes.TEMP_SENSOR) {
            for (let row of currentDay.AggDt) {
                if (checkNonNullKeys(row, ["TS", "T", "TH", "TL"])) {
                    // 0x2 - temp brak odczytu z czujnika
                    // 0x4 - temp za niska
                    // 0x8 - temp za wysoka
                    const isTempAlert = (Number(row.Alm ?? "0") & 0b00001110) > 0;
                    temps.set(row.TS, {
                        name: row.TS,
                        temperature: isBetween(row.T, -3276.8, 3276.7, {inclusivity: "()"}) ? row.T : null,
                        temperatureDesired: null,
                        absoluteAlarm: null,
                        tempRange: (row.TH === 3276.7 || row.TL === -3276.8) ? null : [row.TL, row.TH],
                        alert: isTempAlert
                    })
                    lastTime = getLastHour(row.TS);
                }
            }
        } else {
            for (let row of currentDay.AggDt) {                                       // absolute temp is not in SK2's AggData =(
                if (checkNonNullKeys(row, ["T", "Temp", "TempR", "MinT", "MaxT" /*, "AbsT"*/])) {
                    // check if has alert flag if not go to manual check else check if has any temperature bits (first 4 bits as of 26 march 2021: min al, max al, abs al, out of range)
                    const isTempAlert = isNil(row.Alert) ? (isNil(row.AbsT) ? false : (row.Temp >= row.AbsT)) || !isBetween(row.Temp, row.MinT, row.MaxT) : (row.Alert & 0b00001111) > 0
                    temps.set(row.T, {
                        name: row.T,
                        temperature: row.Temp,
                        temperatureDesired: row.TempR,
                        absoluteAlarm: row.AbsT,
                        tempRange: isNil(row.MaxT) || isNil(row.MinT) ? null : [row.MinT, row.MaxT],
                        alert: isTempAlert
                    })
                    lastTime = getLastHour(row.T);
                }
            }
        }

    }
    return {
        temps,
        lastTime
    };
}

export function calculateDataForVentilationChart(climateData, date) {
    let vents = new Map();
    let lastTime = 0;
    let currentDay = climateData.filter(item => item.AggTi === date)[0];
    if (currentDay) {
        for (let row of currentDay.AggDt) {
            vents.set(row.T, {
                name: row.T,
                temperature: row.Temp,
                ventilation: row.V
            })
        }
        lastTime = getLastHour(currentDay.AggDt[currentDay.AggDt.length - 1].T);
    }
    return {
        vents, lastTime
    };
}

export function calculateDataForVentilationSK3Chart(climateData, date) {
    let vents = new Map();
    let lastTime = 0;
    let currentDay = climateData.find(item => item.AggTi === date);
    if (currentDay) {
        for (let row of currentDay.AggDt) {
            if (checkNonNullKeys(row, ["Temp", "V", "MinV", "MaxV", /*"IntW",*/ "VenS"])) {
                vents.set(row.T, {
                    name: row.T,
                    temperature: row.Temp,
                    ventilation: row.V,
                    chimneys: row.Chim,
                    intermittentWork: !isNil(row.IntW) ? !!row.IntW : null,
                    airing: !isNil(row.VenS) ? !!row.VenS : null,
                    masters: row.Mast,
                    maxMasters: !isNil(row.MaxM) ? row.MaxM : null,
                    ventilationRange: isNil(row.MinV) || isNil(row.MaxV) ? null : [row.MinV, row.MaxV]
                })
                lastTime = getLastHour(row.T);
            }

        }
    }
    return {
        vents, lastTime
    };
}

export function calculateDataForWaterMatSK3Chart(climateData, date) {
    let map = new Map();
    let lastTime = 0;
    const currentDay = climateData.find(item => item.AggTi === date);
    if (currentDay) {
        for (let row of currentDay.AggDt) {
            if (checkNonNullKeys(row, ["WMat.Calc", "WMat.MinT", "WMat.MaxT"])) {
                map.set(row.T, {
                    name: row.T,
                    temperature: row.WMat?.Calc ?? null,
                    manifoldInput: row.WMat?.ManI ?? null,
                    manifoldOutput: row.WMat?.ManO ?? null,
                    regulationDeviation: row.WMat?.RegD ?? null,
                    valveValue: row.WMat?.ValV ?? null,
                    dU2: row.WMat?.dU2 ?? null,
                    dU1: row.WMat?.dU1 ?? null,
                    temperatureRange: isNil(row.WMat?.MinT) || isNil(row.WMat?.MaxT) ? null : [row.WMat.MinT, row.WMat.MaxT],

                })
                lastTime = getLastHour(row.T);
            }
        }
    }
    return {
        map, lastTime
    };
}

export function calculateDataForAirSensorSK3Chart(climateData, date) {
    let map = new Map();
    let lastTime = 0;
    const currentDay = climateData.find(item => item.AggTi === date);
    if (currentDay) {
        for (let row of currentDay.AggDt) {
            if (checkNonNullKeys(row, ["TempR", "Temp"])) {
                map.set(row.T, {
                    name: row.T,
                    requestedTemp: row.TempR,
                    averageTemp: row.Temp,
                    sensor1: row.TSen1,
                    sensor2: row.TSen2,
                    sensor3: row.TSen3,
                    sensor4: row.TSen4
                })
                lastTime = getLastHour(row.T);
            }
        }
    }
    return {
        map, lastTime
    };
}

export function calculateDataForWorkTypeSK3Chart(climateData, date) {
    let workTypes = new Map();
    let lastTime = 0;
    let currentDay = climateData.find(item => item.AggTi === date);
    if (currentDay) {
        for (let row of currentDay.AggDt) {
            if (checkNonNullKeys(row, ["WType"])) {
                workTypes.set(row.T, {
                    name: row.T,
                    workType: row.WType
                })
                lastTime = getLastHour(row.T);
            }

        }
    }
    return {
        workTypes, lastTime
    };
}

export function calculateRFIDChart(cageData, rfid, settlement) {
    let data = settlement.DtaEndTime ? cageData.filter(item => item.AggTi >= settlement.DtaStartTime && item.AggTi <= settlement.DtaEndTime) : cageData.filter(item => item.AggTi >= settlement.DtaStartTime);
    data.sort((a, b) => a.AggTi - b.AggTi);
    let passes = [];
    for (let d of data) {
        let passesForRFID = d.AggDt[0].RFIDPass.find(item => item.RFID === rfid);
        if (passesForRFID) {
            for (let pass of passesForRFID.P) {
                if (pass.W > 0) {
                    passes.push({
                        name: moment(pass.T).format("HH:mm"),
                        weight: pass.W
                    })
                }
            }
        }
    }
    return passes;
}

export function calculateDataForClimateSettingsChart(climateData) {
    console.log(climateData);
    let lastTime;
    let data;
    try {
        data = climateData.AggDt.map(item => ({
            name: item.T,
            curveActive: item.CAct,
            worktype: item.WType
        }));
        let lastItem = data[data.length - 1]
        lastTime = getLastHour(lastItem.name);
    } catch (e) {
        console.error(e)
        lastTime = 0;
        data = [];
    }
    return {
        data,
        lastTime: lastTime
    }

}

export function temperatureConverter(temperature) {
    return convertTemperatureUnitTo(parseFloat(temperature), {
        showUnit: false,
        rawValue: true,
        acceptNil: true,
        fixed: 1,
        unit: UnitTypes.SMALL
    })
}

export function lengthConverter(length) {
    return convertLengthUnitTo(parseFloat(length), {
        showUnit: false,
        rawValue: true,
        acceptNil: true,
        fixed: 1,
        unit: UnitTypes.MEDIUM
    })

}

export function tickFormatter(value) {
    return moment(value).format("HH:mm");
}


export function enabledDisabledFormatter(value) {
    switch (value) {
        case true:
            return '✓';
        case false:
            return '✗'
        default:
            return null;
    }
}

export function minuteConverter(ms) {
    return isNil(ms) ? null : +(ms / 60000).toFixed(2)
}

export function tickPrecisionFormatter(tick) {
    return isNumber(tick) && tick % 1 ? +tick.toFixed(4) : tick
}


export function createTickAndMinDomainByDate(date, maxHours) {
    const ticks = [];
    for (let i = 0; i <= Math.max(maxHours, 1); i++) {
        ticks.push(utcMomentToLocal(date).hour(i).startOf("hour").toDate().getTime());
    }
    return {
        ticks,
        domainMin: utcMomentToLocal(date).startOf("day").toDate().getTime()
    }
}

export function calculateDataForIPSUMFcrChart(aggData, start, end) {
    let durationData = aggData.filter(item => item.AggTi >= start && item.AggTi <= end).sort((o1, o2) => o1.AggTi - o2.AggTi);
    const data = [];
    for (const day of durationData) {
        for (let row of day.AggDt) {
            if (row.hasOwnProperty("FCR")) {
                data.push({
                    name: moment.utc(day.AggTi).format("L"),
                    fcr: row.FCR
                })
            }
        }
    }
    return {data};
}

export function calculateDataForIPSUMGainChart(aggData, start, end) {
    let durationData = aggData.filter(item => item.AggTi >= start && item.AggTi <= end).sort((o1, o2) => o1.AggTi - o2.AggTi);
    let prevDay = null;
    const data = [];
    for (const day of durationData) {
        for (let row of day.AggDt) {
            if (prevDay) {
                let gain = (row.Weight - prevDay.weight) / moment.utc(day.AggTi).diff(prevDay.time, "days");
                if (gain < 0) gain = 0;
                console.log(row.Weight - prevDay.weight, gain);
                data.push({
                    gain,
                    name: moment.utc(day.AggTi).format("L")
                })
            }
            prevDay = {weight: row.Weight, time: day.AggTi};
        }
    }
    return {data};
}

export function arrayValueFormatter(value) {
    if (isArray(value)) {
        return value
            .map((item) => {
                if (isString(item) || isFiniteNumber(item)) return item;
                return null;
            })
            .join(" - ");
    }
    if (isString(value) || isFiniteNumber(value)) return value;

    return null;
}

export const convertMomentToOptions = (momentArray) => momentArray.map((value) => ({
    name: value instanceof moment ? moment.utc(value).format("DD.MM.YY") : `${moment.utc(value.start).format("DD.MM.YY")}-${value.end
        ? moment.utc(value.end).format("DD.MM.YY")
        : "..."
    }`,
    value
}));